;(function($) {

  'use strict'

  var Base = function(el) {
    this.render(el);
  };

  Base.prototype = {

    clsOpen: 'open',
    clsHide: 'close',

    render: function(el) {

      this.el = el;
      this.onEvents();
      return this;

    },

    onEvents: function() {

      if (this.el) {
        this.el.on('click', 'a.serch_result_more_link', $.proxy(this.onToggle, this) );
      }

      return this;

    },

    onToggle: function(evt) {

      evt.preventDefault();
      var t = $(evt.target);
      var p = t.parents('.product_item');

      if (p.length > 0) {
        this.onToggleEl(t, p.find('.order_info_product_more_container'));
      } else {
        this.onToggleAll(t, this.el.find('.product_item'));
      }

    },

    onToggleAll: function(link, elems) {

      var self = this,
          t;

      if (link.hasClass(this.clsOpen)) {

        link.removeClass(this.clsOpen);
        link.addClass(this.clsHide);

        elems.each(function() {

          t = $(this);

          self.onHideEl(
            t.find('.serch_result_more_link'),
            t.find('.order_info_product_more_container')
          );

        });

      } else {

        link.addClass(this.clsOpen);
        link.removeClass(this.clsHide);

        elems.each(function() {

          t = $(this);

          self.onShowEl(
            t.find('.serch_result_more_link'),
            t.find('.order_info_product_more_container')
          );

        })

      }

      return this;

    }, // onToggleAll

    onToggleEl: function(link, block) {

      if (link.hasClass(this.clsOpen)) {
        this.onHideEl(link, block);
      } else {
        this.onShowEl(link, block);
      }

      return this;

    }, // onToggleEl

    onHideEl: function(link, block) {

      if (block.length == 0) { return; }

      link.removeClass(this.clsOpen);
      link.addClass(this.clsHide);
      block.hide();

      return this;

    }, // onHideEl

    onShowEl: function(link, block) {

      if (block.length == 0) { return; }

      link.addClass(this.clsOpen);
      link.removeClass(this.clsHide);
      block.show();

      return this;

    } // onShowEl

  }; // Base.prototype


  $.fn.toggleOrderHistory = function() {

    this.each(function() {
      new Base( $(this) );
    });
    return this;

  }; // toggleOrderHistory

})(jQuery);
