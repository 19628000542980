import HistoryImg from '../../img/ic_search_grey_2.svg';
import TrashImg from '../../img/ic_trash_18.svg';
import SearchImg from '../../img/ic_search_grey_1.svg';
import NoImg from '../../img/gr_noimage_48px.svg';

; (function ($) {

  'use strict'

  var Base = function (suggestions_container, query_container) {
    this.suggestions_container = suggestions_container;
    this.query_container = query_container;
  };

  Base.prototype = {

    dataType: 'json',
    cache: false,
    timeout: 30000,

    // рендерим выпадающее окно
    render: function (data) {

      //Формирование строки с выделением фрагментов
      function title(input, search_item) {
        var words = Array.from(new Set(input.split(/[\s,]+/))); // uniq values
        var result = search_item;
        words.forEach(
          e => { result = result.replace(new RegExp(e, "gi"), (match) => `<#>${match}</#>`); }
        );
        return result.replace(/#/gi, 'mark');
      };

      var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
      var input = this.query_container.val().replace(invalid, "").trim();
      var suggestions = '';

      // формируем контент выпадающего окна
      data.forEach(function (item, id) {
        switch (item.result_type) {
          case 'history':
            var result = item.title.toLowerCase();
            suggestions += `
            <div class="sugg_item" data-sugg_item="`+ id + `">
                <div class="icon">
                    <img src="`+ HistoryImg + `" data-query="` + result + `"/>
                </div>
                <div class="img_block" hidden>
                    <img/>
                </div>
                <div class="main_content">
                    <div class="title" data-query="`+ result + `" data-index="` + id + `">` + title(input, result) + `</div>
                    <div class="description"></div>
                    <div hidden class="price font_bold"><span></span></div>
                </div>
                <div class="icon trash">
                    <img class="tlink" src="`+ TrashImg + `" data-history_id="` + item.id + `" data-del_index="` + id + `"/>
                </div>
            </div>
            `
            break;
          case 'search':
            var result = item.title;
            var second_line = item.brand + ` ` + item.oem_num + ` ` + item.mog;
            var text_price;
            if (item.price == 0) {
              text_price = ''
            } else {
              text_price = item.price + ' ₽'
            }
            suggestions += `
            <div class="sugg_item" data-sugg_item="`+ id + `">
                <div class="icon">
                    <img src="`+ SearchImg + `" data-query="` + result + `"/>
                </div>
                <div class="img_block">
                    <img src="`+ (item.image || NoImg) + `" />
                </div>
                <div class="main_content">
                  <div class="title" data-query="`+ result + ` ` + item.mog + `" data-index="` + id + `">` + title(input, result) + `</div>
                  <div class="description">`+ title(input, second_line) + `</div>
                  <div class="price font_bold"><span></span>`+ text_price + `</div>
                </div>
                <div class="icon trash">
                    <img hidden/>
                </div>
            </div>
            `
            break;
          // case 'brands':
          //   if (item.brands.length > 0) {
          //     var result = 'Бренды: ' + item.brands.join(', ');
          //     suggestions+= `
          //     <div class="sugg_item" data-sugg_item="`+id+`">
          //         <div class="icon">
          //             <img src="`+data.search_img+`" data-query="`+result+`"/>
          //         </div>
          //         <div class="img_block">
          //             <img hidden />
          //         </div>
          //         <div class="main_content">
          //             <div class="title" data-query="`+result+`" data-index="`+id+`">`+result.replace(pattern, "<span data-query='"+result+"' class='find'>$1</span>")+`</div>
          //             <div class="description" hidden></div>
          //             <div class="price font_bold" hidden ><span></span></div>
          //         </div>
          //         <div class="icon trash">
          //             <img hidden/>
          //         </div>
          //     </div>
          //     `
          //   }
          //   break;
          // case 'deps':
          //   if (item.deps.length > 0) {
          //     var result = 'Категории: ' + item.deps.join(', ');
          //     suggestions+= `
          //     <div class="sugg_item" data-sugg_item="`+id+`">
          //         <div class="icon">
          //             <img src="`+data.search_img+`" data-query="`+result+`"/>
          //         </div>
          //         <div class="img_block">
          //             <img hidden />
          //         </div>
          //         <div class="main_content">
          //             <div class="title" data-query="`+result+`" data-index="`+id+`">`+result.replace(pattern, "<span data-query='"+result+"' class='find'>$1</span>")+`</div>
          //             <div class="description" hidden></div>
          //             <div class="price font_bold" hidden><span></span></div>
          //         </div>
          //         <div class="icon trash">
          //             <img hidden/>
          //         </div>
          //     </div>
          //     `
          //   }
          //   break;
          default:
            break;
        }
      })

      // рендерим
      this.suggestions_container.html(suggestions)

      return this;

    }, // render

    isLoading: function () {
      return (this.loading === true);
    },

    onBeforeLoad: function () {

      this.loading = true;
      return this;

    }, // onBeforeLoad

    onComplete: function () {

      this.loading = false;
      return this;

    }, // onComplete

    onSuccess: function (data) {

      this.render(data, this.suggestions_container);

      return this;

    }, // onSuccess

    onFailure: function (xhr, s, e) {
      return this;
    }, // onFailure

    request: function (url, type, data) {

      if (this.isLoading()) {
        return this;
      }

      this.onBeforeLoad();

      $.ajax({
        url: url,
        type: type,
        data: data,
        dataType: this.dataType,
        cache: this.cache,
        context: this,
        complete: this.onComplete,
        success: this.onSuccess,
        error: this.onFailure,
        timeout: this.timeout
      });

      return this;

    } // request

  } // Base.prototype

  /**
   * Строка поиска - автодополнение из истории запросов клиента
   */
  $.fn.autocomplete = function () {

    // селекторы
    const suggestions_container = $(".suggestions"); // выпадающее окно
    const query_container = $("#search, #mobile_search"); // строка запроса
    const search_line = $("#main_search"); // область активации автодополнения
    const search_form = $("#search_form"); // форма поиска

    var base = new Base(suggestions_container, query_container);
    var timerId = null;
    var old_query; //= query_container.val();
    var position = 0;

    // запрос на поиск
    function suggest() {
      let new_query = $(document.activeElement).val().trim();
      if (new_query != old_query) {
        suggestions_container.show();
        base.request('/autocomplete', 'GET', { q: new_query });
      }
      old_query = new_query;
      let max = max_position();
      if (position > max) position = max;
    };

    // старт поиска
    function start_suggestions() {
      if (timerId === null) {
        timerId = setInterval(() => suggest(), 500);
      };
      suggestions_container.show();
    };

    // остановка поиска
    function stop_suggestions() {
      clearInterval(timerId);
      timerId = null;
      suggestions_container.hide();
    };

    // отправка формы
    function send_form(query) {
      if (query !== undefined) {
        query_container.val(query);
        suggestions_container.empty();
        search_form.submit();
        query_container.prop("disabled", true);
      }
    };

    // запрос на удаление записи истории
    function delete_history(history_id) {
      if (history_id !== undefined) {
        base.request('/remove_history', 'DELETE', { id: history_id, q: query_container.val().trim() });
      }
    }

    // кол-во строк
    function max_position() {
      return parseInt(suggestions_container.children().last().attr('data-sugg_item')) + 1 || 0;
    }

    // удаляем вариант из истории - мышь
    suggestions_container.mouseup(function (e) {
      let max = max_position();
      delete_history(e.target.dataset.history_id);
      if (position > max) position = max;
      query_container.focus();
    });

    // отправляем форму при выборе варианта - мышь
    suggestions_container.mouseup(function (e) {
      send_form(e.target.dataset.query)
    });

    // выделение текущей строки
    function show(position) {
      if (position > 0) {
        $('.sugg_item').css("background", "#FFFFFF");
        $('*[data-sugg_item="' + (position - 1) + '"]'[0]).css("background", "#f3f4f6");
      }
    }

    // обработка событий клавиатуры
    query_container.on('keydown', function (e) {
      let max = max_position();
      switch (e.keyCode) {
        case 38: // down key
          position < 2 ? 1 : position--
          break;
        case 40: // up key
          position >= max ? max : position++
          break;
        case 13: // enter
          if (position > 0) {
            send_form($('*[data-index="' + (position - 1) + '"]')[0].dataset.query);
          };
          break;
        case 9: // tab
          stop_suggestions();
          break;
        // Удаление по DEL клавише - возможно если разрулить конфликт с DEL в INPUT
        //case 46: // delete key
        //  if (position > 0) {
        //    delete_history($('*[data-del_index="'+(position-1)+'"]')[0].dataset.history_id)
        //  };
        //  if (position>max) position=max;
        //  break;
      }
      show(position);
    });

    // работаем только когда поиск в фокусе
    query_container.on('input focus', function () {
      start_suggestions();
    });
    $(window).click(function () {
      stop_suggestions();
    });
    search_line.click(function (e) {
      e.stopPropagation();
      start_suggestions();
    });

    return this;

  }; // $.fn.autocomplete

})(jQuery);
