;(function($) {

  'use strict'

  var NoticeSettings = function(el) {
    this.render(el);
  };

  NoticeSettings.prototype = {

    timeout:    10,
    url:        '/notices/settings',
    method:     'PATCH',

    render: function(el) {

      if (!this.el) {

        this.el = el;
        this.onEvents();

      }

      return this;

    }, // render

    onEvents: function() {

      this.el.on(
        'click touchstart',
        'input[type="checkbox"]',
        this.onClick.bind(this)
      );

      return this;

    }, // onEvents

    onClick: function(evt) {

      evt.stopPropagation();
      let el = $(evt.currentTarget);

      this.sendRequest({
        t:  el.data('t'),
        l:  el.data('l')
      });

    }, // onClick

    sendRequest: function(data) {

      $.ajax({
        url:      this.url + '?' + (+new Date()),
        type:     this.method,
        dataType: "json",
        timeout:  this.timeout*1000,
        data:     data,

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

    }, // sendRequest

    onSuccess: function(r, s, xhr) {

      let el = this.el.find(
        'input[data-t=' + r.t +'][data-l=' + r.l +']'
      );

      if (r.result == 'inserted') {
        el.prop('checked', true);
      } else {
        el.prop('checked', false);
      }

      return this;

    }, // onSuccess

    onComplete: function() {},
    onFailure: function() {}

  }; // NoticeSettings

  /**
   *  Настройки уведомлений
   */
  $.fn.notifications_settings = function() {

    this.each(function() {
      new NoticeSettings($(this));
    });

    return this;

  }; // notifications

})(jQuery);