;(function($) {

  'use strict'

  $.fn.adv = function() {

    var popup = this.find('.ad_popup');

    var fn = function() {

      if ($(window).width() >= 768) {
        popup.removeClass('mobile').addClass('desktop');
      } else {
        popup.removeClass('desktop').addClass('mobile');
      }

    };

    fn();
    $(window).resize(fn);

    this.find('.placeholder_inner').click(popup.fadeToggle.bind(popup));
    popup.mouseleave(popup.fadeOut.bind(popup));

    return this;

  };

})(jQuery);
