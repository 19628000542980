;(function($) {

  'use strict'

  var Base = function(el) {
    this.render(el);
  };

  Base.prototype = {

    dataType:   'html',
    cache:      false,
    timeout:    30000,

    render: function(el) {

      if (!this.el) {
        this.el   = el;
        this.cnt  = el.parents('div.search_result_table');
      }

      return this;

    }, // render

    getUrl: function() {

      var url = new URL(this.el.attr('action')),
          arr = this.el.serializeArray(),
          sp  = url.searchParams,
          el;

      while(el = arr.pop()) {

        if (this.isArray(el.name) || !sp.has(el.name)) {
          sp.append(el.name, el.value);
        }

      }

      return url.toString();

    },

    isArray: function(s) {
      return /\w\[\]/i.test(s);
    },

    isLoading: function() {
      return (this.loading === true);
    },

    onBeforeLoad: function() {

      this.loading = true;
      return this;

    }, // onBeforeLoad

    onComplete: function() {

      this.loading = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      var html = $(String(r)).find('div.search_result_table_row');
      this.cnt.html(html);

      $(document).trigger($.Event('page:change'));

      //delivery popup re-init
      var outlet_window = document.querySelectorAll('.modal_select_delivery_popup_container');
      var outlet_window_inst = M.Modal.init(outlet_window, {});
      
      $(".loader").hide();
      
      return this;

    }, // onSuccess

    onFailure: function(xhr, s, e) {
      return this;
    }, // onFailure

    request: function(type, val) {

      if (this.isLoading()) {
        return this;
      }

      this.onBeforeLoad();
      
      $(".loader").show();

      var url = this.getUrl();
      window.changeUrl(url);

      $.ajax({

        url:          url,
        type:         'GET',
        dataType:     this.dataType,
        cache:        this.cache,

        context:      this,
        complete:     this.onComplete,
        success:      this.onSuccess,
        error:        this.onFailure,

        timeout:      this.timeout

      });

      return this;

    } // request

  } // Base.prototype

  /**
   * Фильтрация данных при изменениях в фильтре с ajax обновление части страницы
   */
  $.fn.ajaxFilterForm = function(sel) {

    this.each(function() {

      let el = $(this);

      el.on('change', sel + '> form input[attr="min"]', function(e) {
        let base = new Base($(e.target).parents('form'));
        base.request();
      });

      el.on('click', sel + ' > form a', function(e) {
        // exclude outlet select link
        if($(e.target).is('.modal-trigger')){ return true; }
        let base = new Base($(e.target).parents('form'));
        base.request();
      });

      el.on('click', sel + ' > form input[type="checkbox"]', function(e) {

        let base = new Base($(e.target).parents('form'));
        base.request();

      });

      el.on('change', sel + ' > form select', function(e) {

        let base = new Base($(e.target).parents('form'));
        base.request();

      });

    });

    return this;

  }; // $.fn.ajaxFilterForm

})(jQuery);
