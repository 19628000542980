import { FormModal } from './forms_modal.js';

;(function($) {

  'use strict'

  let MARKUP_FORM_TMPL = `
    <div id="modal_add_discount" class="mobile_filter_window_container modal modal_add_discount">
      <div class="modal-content">
          <div class="font_bold title">Наценка по диапазону цен</div>
          <div class="input-group">
              <div class="input-field">
                  <input id="s_price" type="text" class="validate" name="s_price" data-position="top">
                  <label for="s_price">От</label>
              </div>
              <div class="font_bold" style="margin-bottom: 16px">...</div>
              <div class="input-field">
                  <input id="e_price" type="text" class="validate" name="e_price" data-position="top">
                  <label for="e_price">До</label>
              </div>
              <div class="input-field">
                  <input id="markup" type="text" class="validate" name="markup" data-position="top">
                  <label for="markup">Наценка в %</label>
              </div>
          </div>
          <div class="alert"></div>
      </div>
      <div class="border"></div>
      <div class="modal-footer modal_btn_footer_flex_container">
        <div class="controls">
          <button class="btn_outline_black_big tlink modal-close">Отмена</button>
          <button attr="save" class="blue_button tlink">Сохранить</button>
        </div>
      </div>
      <a class="btn_close_modal tlink modal-close" href="#">‹ Назад</a>
    </div>
  `;

  let MARKUP_INFO_TMPL = `
    <div id="modal_percent" class="mobile_filter_window_container modal new_modal modal_percent">
      <div class="modal-content">
        <div class="switch_block">
          <div class="font_bold title">Использовать наценку</div>
          <div class="switch">
            <div class="switch">
              <label>
                <input data-type="markup-switcher" class="sw_check" type="checkbox">
                <span class="lever"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="main_discount">
            <div class="title font_medium">Базовая наценка</div>
            <div data-type="markup-general" class="percent font_bold">0%</div>
          </div>
          <div class="discount_block__wrapper" data-type="markup-list">
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="modal-footer modal_btn_footer_flex_container">
        <div class="controls">
          <a href="/user/markups" class="blue_btn tlink modal-close">Настроить наценку</a>
        </div>
      </div>
      <a class="btn_close_modal tlink modal-close" href="#">‹ Назад</a>
    </div>
  `;

  let MARKUP_EL_TMPL = `
    <div class="discount_block">
      <div class="discount_range__wrapper">
        <div class="discount_range">{s_price} ... {e_price} ₽</div>
      </div>
      <div  class="discount_percent font_bold">{markup}%</div>
    </div>
  `;

  var MarkupSwitcher = function() {
    this.render();
  };

  MarkupSwitcher.prototype = {

    url:      '/user/markups/switch',
    method:   'PUT',
    timeout:  30,

    render: function() {

      this.onEvents();
      return this;

    },

    onEvents: function() {

      $('body').on('click', 'input[data-type="markup-switcher"]', this.sendRequest.bind(this));
      return this;

    },

    sendRequest: function() {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url:      this.url,
        type:     this.method,
        dataType: "json",
        timeout:  this.timeout*1000,

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

      return this;

    }, // sendRequest

    onComplete: function() {

      this.sending = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      this.onSet(r.markuped);
      return this;

    }, // onSuccess

    onFailure: function() {
    }, // onFailure

    onSet: function(r) {

      if (r) {

        $('a.discount.markup_panel').addClass('active');
        $('input[data-type="markup-switcher"]').prop('checked', true);

      } else {

        $('a.discount.markup_panel').removeClass('active');
        $('input[data-type="markup-switcher"]').prop('checked', false);

      }

      return this;

    } // onSet

  }; // MarkupSwitcher

  var MarkupGeneral = function(el) {
    this.render(el);
  };

  MarkupGeneral.prototype = {

    url:      '/user/markups/general',
    method:   'PUT',
    timeout:  30,

    render: function(el) {

      if (!this.el) {
        this.el = el;
      }
      this.onEvents();
      return this;

    }, // render

    onEvents: function() {

      this.el.on('keyup', this.onChange.bind(this));
      return this;

    }, // onEvents

    onChange: function(evt) {

      evt.stopPropagation();

      this.t && clearTimeout(this.t);
      this.t = setTimeout(this.sendRequest.bind(this), 500);

      return false;

    }, // onChange

    sendRequest: function() {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url:      this.url,
        type:     this.method,
        dataType: "json",
        timeout:  this.timeout*1000,

        data: {
          markup: this.el.val()
        },

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

      return this;

    }, // sendRequest

    onComplete: function() {

      this.sending = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      this.el.val(r.markup);
      return this;

    }, // onSuccess

    onFailure: function() {
    } // onFailure

  }; // MarkupGeneral

  var MarkupInfoModal = function() {
    this.render();
  };

  MarkupInfoModal.prototype = {

    url:      '/user/markups/info',
    method:   'GET',
    timeout:  30,

    render: function(el) {

      this.el = $(MARKUP_INFO_TMPL).appendTo('body');

      this.onRender();

      this.modal = M.Modal.init(this.el[0], {
        opacity:  '0.9'
      });

      this.onEvents();

    }, // render

    onRender: function() {

      this.switcher = this.el.find('input[data-type="markup-switcher"]');
      this.general  = this.el.find('div[data-type="markup-general"]');
      this.list     = this.el.find('div[data-type="markup-list"]');

      return this;

    }, // onRender

    onEvents: function() {

      this.switcher.on('change', this.onSwitch.bind(this));
      return this;

    }, // onEvents

    onSwitch: function() {

      if (this.switcher.prop('checked')) {
        this.list.fadeIn();
      } else {
        this.list.fadeOut();
      }

      window.refreshLocation();

      return this;

    }, // onSwitch

    show: function() {

      this.modal.open();
      return this

    }, // show

    hide: function() {

      this.modal.close();
      return this

    }, // hide

    load: function() {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url:      this.url,
        type:     this.method,
        dataType: "json",
        timeout:  this.timeout*1000,

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

      return this;

    }, // load

    onComplete: function() {

      this.sending = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      this.onLoad(r)
      return this;

    }, // onSuccess

    onFailure: function() {
    }, // onFailure

    onLoad: function(d) {

      this.switcher.prop('checked', d.status);
      this.general.text(d.general + '%');

      this.onCreateList(d.markups);

      this.show();
      return this;

    }, // onLoad

    onCreateList: function(datas) {

      let str = '';
      let i   = 0;
      let l   = datas.length;

      for(; i < l; i++) {
        str += String.format(MARKUP_EL_TMPL, datas[i])
      }

      this.list.html(str);

      if (this.switcher.prop('checked')) {
        this.list.show();
      } else {
        this.list.hide();
      }

      return this;

    }

  }; // MarkupInfoModal

  var MarkupManager = function(el) {
    this.init(el);
  };

  MarkupManager.prototype = {

    timeout: 30,

    init: function(el) {
      this.el = el;
    },

    create: function() {

      this.el.reset();
      this.el.save = this.onCreate.bind(this);
      this.el.show();

      return this;

    }, // create

    onCreate: function() {

      this.sendRequest(
        this.el.values(),
        '/user/markups',
        'POST'
      );

      return this;

    }, // onCreate

    edit: function(id) {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url:      '/user/markups/' + id,
        type:     'GET',
        dataType: "json",
        timeout:  this.timeout*1000,

        context:  this,

        complete: this.onComplete,
        success:  this.onLoad

      });

      return this;

    }, // edit

    onEdit: function(id) {

      this.sendRequest(
        this.el.values(),
        '/user/markups/' + id,
        'PATCH'
      );

      return this;

    }, // onEdit

    onLoad: function(r, s, xhr) {

      this.el.reset();
      this.el.load(r);
      this.el.save = this.onEdit.bind(this, r.id);
      this.el.show();

      return this;

    }, // onLoad

    sendRequest: function(values, url, method) {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url:      url,
        type:     method,
        dataType: "json",
        timeout:  this.timeout*1000,

        data: {
          markup: values
        },

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

      return this;

    }, // sendRequest

    onComplete: function() {

      this.sending = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      this.el.hide();
      window.refreshLocation();

      return this;

    }, // onSuccess

    onFailure: function(xhr, s, r) {

      if (xhr.status == 404) {
        this.el.markErrors({ base: 'Ресурс не найден' })
      } else if (xhr.status == 400) {
        this.el.markErrors(xhr.responseJSON.errors);
      } else {
        this.el.markErrors({ base: 'Возникли ошибки' })
      }

      return this;

    } // onFailure

  }

  /**
   * Включение/выключение применимости наценок
   */
  $.fn.markup_switcher = function() {

    this.each(function() {
      new MarkupSwitcher();
    });

  };

  /**
   * Установка общей наценки
   */
  $.fn.markup_general = function() {

    this.each(function() {
      new MarkupGeneral($(this));
    });

  };

  /**
   * Добавление, редактирование диапазона наценки
   */
  $.fn.markup_manager = function() {

    let win = new FormModal(MARKUP_FORM_TMPL);
    let mm  = new MarkupManager(win);

    this.on('click', 'a[data-type="markup-create"]', function(evt) {

      evt.preventDefault();
      mm.create();

    });

    this.on('click', 'a[data-type="markup-edit"]', function(evt) {

      evt.preventDefault();
      mm.edit($(this).data('id'));

    });

    return this;

  };

  /**
   * Панель с информацией по наценке
   */
  $.fn.markup_info_panel = function() {

    let win = new MarkupInfoModal();

    this.on('click', function(evt) {

      evt.stopPropagation();
      win.load();
      return false;

    });

  };

})(jQuery);
