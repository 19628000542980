require("@rails/ujs").start()
require("@rails/activestorage").start()

import 'fonts';
import 'img';
import 'css/application';

import 'jquery';
window.$ = window.jQuery = jQuery;

import 'materialize-css/dist/js/materialize';
import 'js/application';

//