import { FormModal } from './forms_modal.js';

; (function ($) {

  'use strict'

  let CART_TMPL = `
    <div class="mobile_filter_window_container modal modal-fixed-footer">
      <div class="advanced_search_filter modal-content">
        <div class="price modal_caption">
          <span attr="price">{price}</span><span class="rur">⃏</span>
        </div>
        <div class="modal_cart_product_name" attr="name">{name}</div>
        <div class="product_brand"><span><a class="active" attr="oem_brand" href="#">{oem_brand}</a></span>&nbsp;<span><a attr="oem_num" href="#">{oem_num}</a></span>&nbsp;<span attr="mog">{mog}</span>&nbsp;<span attr="ucode">{ucode}</span></div>
        <div class="modal_cart_product_img" attr="image"></div>
        <a attr="favorite" class="favorites_txt_button" href="#"><span>{active_text}</span></a>

        <div class="modal_cart_param_container">
          <div class="modal_cart_param_item">
              <div class="caption">Наличие</div>
              <div attr="available_stock" class="txt">На складе</div>
              <div class="stock_counter">
                  <div attr="available_level" class="stock_indicator level0">
                      <div class="txt">Нет в наличии</div>
                      <div class="counter"></div>
                      <div class="city_stock">
                        <div attr="available"></div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

      </div>

      <div class="modal-footer">
          <div class="modal_btn_footer_flex_container">
              <div class="quantity_container">
                  <div>
                    <button attr="minus_count" class="btn_quantity outline_grey_button tlink">-</button>
                    <div class="quantity_field input-field">
                      <input attr="count" value="1" type="number" min="1" class="validate" />
                    </div>
                    <button attr="plus_count" class="btn_quantity outline_grey_button tlink">+</button>
                  </div>
                  <div class="search_item_line_small quantity_multiplicity multiplier"><span>кратность:</span><span attr="shipment" class="multiplier">10</span></div>
              </div>
              <div class="add_cart_btn_container">
                <button attr="send_to_basket" class="btn_apply fill_blue_button tlink">Добавить</button>
                <div class="cart_quantity_link search_item_line_small"><span attr="basket_count">0</span> <a class="link_dashed" href="/orders">в корзине</a></div>
              </div>
          </div>
          <a class="btn_close_modal tlink modal-close">&lsaquo; Назад</a>
      </div>
    </div>
  `;

  let DELETE_LINK_TMPL = `
    <div class="search_item_line_small del_from_cart_link"><a class="tlink" href="#">удалить</a></div>
  `;

  let ITEM_BASKET_TMPL = `
    <div class="{cls}">
      <a attr="current-basket" style="background-color: {color}" class="pr-dropdown-multicart dropdown-trigger" data-order-id="{order_id}" data-target="baskets-list-{list_id}">{title}</a>
      <ul id="baskets-list-{list_id}" attr="user-baskets-list" class="user_dropdown_menu dropdown-content" style="width: fit-content">
        {list}
      </ul>
    </div>
  `;

  let BASKETS_EL_TMPL = `
    <li><a href="#" attr="set-basket" data-order-id="{order_id}" class="font_bold">{title}</a></li>
  `;

  let BASKETS_EL_CONST_TMPL = `
    <li class="divider" tabindex="-1"></li>
    <li><a href="#" attr="create-basket" class="font_bold">В новую корзину</a></li>
  `;

  let CREATE_BASKET_FORM_TMPL = `
    <div class="modal new_modal new_cart" style="max-width: 700px;">
      <div class="modal-content">
        <h5>Дополнительная корзина</h5>
        <div>
          Подробнее о функциях мультикорзины можно узнать в разделе
          <a href="https://voshod.help/kb/cart">справки</a>
        </div>
        <br />
        <div class="input-field">
          <input id="title" name="title" type="text" class="validate" data-position="top">
          <label for="title">Название корзины</label>
        </div>
        <div class="alert"></div>
      </div>
      <div class="border"></div>
      <div class="modal-footer">
        <div class="controls">
          <button class="btn_outline_grey_big tlink modal-close">Отменить</button>
          <button attr="save" class="blue_button tlink">Применить</button>
        </div>
      </div>
    </div>
  `;

  /*
   * Класс по работе с окном корзины товара
   */
  let ModalCart = function () {
    this.render();
  }

  ModalCart.prototype = {

    timeout: 55,

    render: function () {

      if (!this.el) {

        this.el = $(CART_TMPL).appendTo('body');
        this.modal = M.Modal.init(this.el[0], {
          opacity: '0.9'
        });

        this.onRender();

      }

      return this;

    }, // render

    show: function () {

      this.modal.open();
      this.disableSendToBasket(this.getCount());
      return this

    }, // show

    hide: function () {

      this.modal.close();
      return this

    }, // hide

    setDatas: function (datas, owner) {

      this.owner = owner;
      this.datas = datas;

      this.onSetName(datas);
      this.onSetProductParams(datas);
      this.onSetPrice(datas);
      this.onSetFavorite(datas);
      this.onSetShipment(datas);
      this.onSetAvailable(datas);
      this.onSetBasket(datas);
      this.onSetExist(datas);

      this.onSetImage(datas);

      return this;

    }, // setDatas

    onRender: function () {

      if (this.el) {

        this.priceEl = this.el.find('[attr="price"]');
        this.nameEl = this.el.find('[attr="name"]');
        this.imageEl = this.el.find('[attr="image"]');

        this.oemBrandEl = this.el.find('[attr="oem_brand"]');
        this.oemNumEl = this.el.find('[attr="oem_num"]');
        this.mogEl = this.el.find('[attr="mog"]');

        this.favoriteEl = this.el.find('[attr="favorite"]');
        this.shipmentEl = this.el.find('[attr="shipment"]');

        this.basketCountEl = this.el.find('[attr="basket_count"]');
        this.availableStockEl = this.el.find('[attr="available_stock"]');
        this.availableLevelEl = this.el.find('[attr="available_level"]');

        this.available = this.el.find('[attr="available"]');
        // this.availableChelEl = this.el.find('[attr="available_chel"]');
        // this.availableEkbEl = this.el.find('[attr="available_ekb"]');
        // this.availableMagEl = this.el.find('[attr="available_mag"]');
        // this.availableMagEl = this.el.find('[attr="available_surgut"]');

        this.sendToBasketEl = this.el.find('[attr="send_to_basket"]');
        this.plusCountEl = this.el.find('[attr="plus_count"]');
        this.minusCountEl = this.el.find('[attr="minus_count"]');
        this.countEl = this.el.find('[attr="count"]');
        this.existEl = this.el.find('[attr="exist"]');

        this.ucodeEl = this.el.find('[attr="ucode"]');

        this.onEvents();

      }

      return this;

    }, // onRender

    onEvents: function () {

      this.favoriteEl.favoriteItemUpdater(
        null,
        $.proxy(this.updateFavoriteState, this)
      );

      this.plusCountEl.on('click', $.proxy(this.plusCount, this));
      this.minusCountEl.on('click', $.proxy(this.minusCount, this));
      this.sendToBasketEl.on('click', $.proxy(this.sendToBasket, this));
      this.countEl.on('keyup', $.proxy(this.onCountKeyUp, this));

      return this;

    }, // onEvents

    onCountKeyUp: function (evt) {
      let count = this.getCount();
      this.disableSendToBasket(count);
    },

    disableSendToBasket: function (count) {
      if (count > 0) {
        this.sendToBasketEl.removeAttr('disabled');
      } else {
        this.sendToBasketEl.attr('disabled', 'disabled');
      }
    },

    plusCount: function () {
      console.log('plusCount')

      let newCount = this.getCount() + this.getShipment();

      this.disableSendToBasket(newCount);
      this.setCount(newCount);

      return this;

    }, // plusCount

    minusCount: function () {

      let newCount = this.getCount() - this.getShipment();

      this.disableSendToBasket(newCount);
      this.setCount(newCount);

      return this;

    }, // minusCount

    sendToBasket: function () {

      console.log('sendToBasket')

      if (this.disabled === true) { return this; }

      this.onDisable();

      $.ajax({
        url: '/cart_items',
        type: 'PATCH',
        dataType: "json",
        timeout: this.timeout * 1000,
        data: {
          item: this.getValues()
        },

        context: this,

        complete: this.onComplete,
        success: this.onSuccess,
        error: this.onFailure

      });

      return this;

    }, // sendToBasket

    getValues: function () {

      return {
        'item_id': this.datas['itemId'],
        'count': this.getCount()
      }

    }, // getValues

    onDisable: function () {

      this.sendToBasketEl.attr("disabled", true);
      this.sendToBasketEl.addClass("disabled");
      this.disabled = true;
      return this;

    }, // onDisable

    onEnable: function () {

      this.sendToBasketEl.attr("disabled", false);
      this.sendToBasketEl.removeClass("disabled");
      this.disabled = false;
      return this;

    }, // onEnable

    onComplete: function () {

      this.onEnable();

      return this;

    }, // onComplete

    onBasketsList: function (res) {

      let list = this.createBasketsList(res.baskets);
      let show = res.baskets.length > 1;

      this.onRefreshAllBasketsList(list, show);


      let bsk = this.owner.find('[attr="basket-item"]');
      bsk.html(

        String.format(ITEM_BASKET_TMPL, {
          cls: show ? '' : 'hide',
          order_id: res.current_basket.order_id,
          title: res.current_basket.title,
          list_id: M.guid(),
          list: list,
          color: res.current_basket.color
        })

      );

      return this;
    },

    onRefreshAllBasketsList: function (list, show) {

      this.owner.find('[attr="basket-item"]').
        find('[attr="user-baskets-list"]').
        html(list);

      if (show) {
        this.owner.find('[attr="basket-item"] > div').removeClass('hide');
      } else {
        this.owner.find('[attr="basket-item"] > div').addClass('hide');
      }

      return this;

    }, // onRefreshAllBasketsList

    refreshDropdownTrigger: function () {

      M.Dropdown.init(this.owner.find('.dropdown-trigger'), {
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        coverTrigger: false,
        alignment: 'left',
        autoTrigger: false,
        autoFocus: false
      });

      return this;

    }, // refreshDropdownTrigger

    createBasketsList: function (baskets) {

      let list = '';
      let i = 0,
        l = baskets.length,
        el;

      // Собираем список доступных корзин
      for (; i < l; i++) {

        el = baskets[i];
        list += (String.format(BASKETS_EL_TMPL, {
          order_id: el[0],
          title: el[1]
        }));

      }

      // Добавляем "хвост"
      list += BASKETS_EL_CONST_TMPL;

      return list;

    }, // createBasketsList

    onSuccess: function (r, s, xhr) {

      console.log('response: ', r);

      $(document).trigger($.Event("basket.update"), r.response);

      this.updateBasketState(
        parseInt(r.response['count'], 10) || 0
      );

      this.onBasketsList(r.response);
      this.refreshDropdownTrigger();
      this.hide();

      return this;

    }, // onSuccess

    onFailure: function (r, s, xhr) {

      $(document).trigger($.Event("basket.failure"), r);

      return this;

    }, // onFailure

    getCount: function () {

      let v = parseInt(this.countEl.val(), 10) || 0;
      return (v < 0 ? 0 : v);

    }, // getCount

    setCount: function (count) {

      this.countEl.val(
        this.onCalculateCount(count, this.getShipment())
      );
      return this;

    }, // setCount

    onCalculateCount: function (count, shipment) {

      if (count <= 0) {
        return 0;
      }

      if (shipment <= 1) {
        return count;
      }

      let arr = [
        Math.floor(count / shipment),
        count % shipment
      ];

      return (arr[0] * shipment + (arr[1] == 0 ? 0 : shipment));

    }, // onCalculateCount

    getShipment: function () {

      let v = parseInt(this.datas['shipment'], 10) || 1;
      return (v < 1 ? 1 : v);

    }, // getShipment

    /*
     *  Обновляем состояние кнопки избраного в окне и в нужной строке товара на странице
     */
    updateFavoriteState: function (act, el) {

      let fav = this.owner.find('a.favorites_button');

      if (act == 'add') {

        el.find('span').text('Удалить из избранного');
        fav.addClass('active');
        fav.data('fav', '1');

      } else {

        el.find('span').text('Добавить в избранное');
        fav.removeClass('active');
        fav.data('fav', '0');

      }

      return this;

    }, // updateFavoriteState

    /*
     * Обновляем счетчик у товара.
     */
    updateBasketState: function (count) {

      this.basketCountEl.text(count);
      this.setCount(count);

      let bsk = this.owner.find('[attr="basket"]');

      bsk.data('count', count);
      bsk.find('span.slideExpandUp').text(count);

      bsk.removeClass("cart_status_added");
      bsk.removeClass("cart_status_del");

      let ic = this.owner.find('input[attr="item_counter"]');
      ic.val(count);

      if (count > 0) {

        let lnk = this.owner.find('div.del_from_cart_link');
        if (lnk.length == 0) {
          bsk.after(DELETE_LINK_TMPL);
        }

        bsk.addClass("cart_status_added");

      } else {
        this.owner.find('div.del_from_cart_link').remove();
      }

      return this;

    }, // updateBasketState

    onSetName: function (datas) {

      let name = String(datas['name']);
      if (datas['novelty'] == '1') {
        name = '<span class="new_lable">Новинка</span> ' + name;
      }

      this.nameEl.html(name);
      return this;

    }, // onSetName

    onSetProductParams: function (datas) {

      this.oemBrandEl.attr('href', '/search?q=' + datas['oem_brand'] + ', ' + datas['oem_num']);
      this.oemBrandEl.text(datas['oem_brand']);

      this.oemNumEl.attr('href', '/search?q=' + datas['oem_num']);
      this.oemNumEl.text(datas['oem_num']);

      this.mogEl.text(datas['mog']);

      this.ucodeEl.text(datas['ucode']);

      return this;

    }, // onSetProductParams

    onSetPrice: function (datas) {

      this.priceEl.text(datas['price']);
      return this;

    }, // onSetPrice

    onSetFavorite: function (datas) {

      let d = datas['favorite'];

      this.favoriteEl.attr('href', d['url']);
      this.favoriteEl.removeClass('active');

      if (d['fav'] == '0' || d['fav'] == 0) {
        this.favoriteEl.find('span').text('Добавить в избранное');
      } else {
        this.favoriteEl.addClass('active');
        this.favoriteEl.find('span').text('Удалить из избранного');
      }

      return this;

    }, // onSetFavorite

    onSetShipment: function (datas) {

      this.shipmentEl.text(datas['shipment']);
      this.shipmentEl.removeClass('multiplier_one');

      let sh = parseInt(datas['shipment'], 10) || 0;
      if (sh <= 1) {
        this.shipmentEl.addClass('multiplier_one');
      }

      return this;

    }, // onSetShipment

    onSetAvailable: function (datas) {

      if (datas['stock_ind'] == 'level0') {
        this.availableStockEl.hide();
      } else {
        this.availableStockEl.show();
      }

      this.availableLevelEl.attr('class', 'stock_indicator ' + datas['stock_ind']);


      var stores = datas['count'];
      var content = "";
      for (const store of stores) {
        content += '<div class="search_item_line_small">' + store[0] + ' <span class="city_stock_counter">' + store[1] + '</span></div>'
      }
      this.available.html(content);

      return this;

    }, // onSetAvailable

    onSetBasket: function (datas) {

      this.basketCountEl.text(datas['basket']);
      this.setCount(datas['basket']);
      return this;

    }, // onSetBasket

    onSetExist: function (datas) {

      datas['exist'] == '1' ? this.onEnable() : this.onDisable();
      return this;

    }, // onSetExist

    onSetImage: function (datas) {

      let img = datas['image'];
      if ((img != null) && (img != '')) {

        this.imageEl.html(
          '<img src="' + img + '" alt=""/>'
        );

      } else {
        this.imageEl.html('');
      }

      return this;

    } // onSetImage

  }; // ModalCart.prototype

  /*
   * Класс по работе с модальный окном
   */
  let ButtonModalCart = function (sel) {

    this.globalModalCart = new ModalCart();
    this.render(sel);

  };

  ButtonModalCart.prototype = {

    render: function (sel) {

      if (!this.sel) {

        this.sel = sel;
        this.onEvents();

      }
      return this;

    }, // render

    onEvents: function () {

      if (this.sel) {
        $('body').on('click', this.sel + ' .btn_cart_modal', $.proxy(this.onClick, this));
      }

      return this;

    }, // onEvents

    onClick: function (evt) {

      evt.preventDefault();

      let el = $(evt.target).parents(this.sel);

      this.globalModalCart.setDatas(this.onParseDatas(el), el);
      this.globalModalCart.show();

    }, // onClick

    onParseDatas: function (el) {

      console.log(el.data())
      let item = el.data() || {};
      let fav = el.find('[attr="favorite"]');

      item['basket'] = el.find('[attr="basket"]').data('count') || 0;
      item['image'] = el.find('[attr="image"]').data('image');
      item['favorite'] = {
        url: fav.attr('href'),
        fav: fav.data('fav')
      }

      return item;

    } // onParseDatas

  }; // BaseModalCart.prototype


  /*
    * Класс по работе с кнопками добавления товара в корзину
    */
  let Basket = function (el) {
    this.render(el);
  }

  Basket.prototype = {

    timeout: 55,

    // Всплывающее окно создание новой корзины
    getWcb: function () {

      if (!Basket.wcb) {
        Basket.wcb = new FormModal(CREATE_BASKET_FORM_TMPL);
      }
      return Basket.wcb;

    },

    render: function (el) {

      if (!this.el) {

        this.el = el;

        this.countEl = el.find('input[attr="item_counter"]');
        this.buttonCart = el.find('[attr="basket"]');
        this.minusEl = el.find('[attr="minus_count"]');
        this.plusEl = el.find('[attr="plus_count"]');

        this.basketItem = el.find('[attr="basket-item"]');

        this.onParseDatas();
        this.onEvents();

      }

      return this;

    }, // render

    onParseDatas: function () {

      this.datas = this.el.data() || {};
      this.datas['basket'] = this.el.find('[attr="basket"]').data('count') || 0;
      this.countBefore = this.datas['basket'];

      return this;

    }, // onParseDatas

    getOrderId: function () {
      return this.el.find('div[attr="basket-item"] a[data-order-id]').data('order-id');
    },

    onEvents: function () {

      // this.buttonCart.on('click', this.onPlus.bind(this));
      this.minusEl.on('click', this.onMinus.bind(this));
      this.plusEl.on('click', this.onPlus.bind(this));
      this.countEl.on('keyup', this.onChange.bind(this));

      // Ссылка удаления товара из корзины
      this.el.on('click', 'div.del_from_cart_link > a', this.onDelete.bind(this));

      // Создание новой корзины и добавление товара в нее.
      this.el.on('click', 'a[attr="create-basket"]', this.onCreateAndApplyBasket.bind(this));

      // Перемещение товара в другую корзину
      this.el.on('click', 'a[attr="set-basket"]', this.onMoveItemBasket.bind(this));

      return this;

    }, // onEvents

    onResetStatus: function () {

      this.buttonCart.removeClass("cart_status_added");
      this.buttonCart.removeClass("cart_status_del");

      return this;

    }, // onResetStatus

    onStatusAdded: function () {

      this.buttonCart.addClass("cart_status_added");
      return this;

    }, // onStatusAdded

    onStatusDel: function () {

      this.buttonCart.addClass("cart_status_del");
      return this;

    }, // onStatusDel

    onMinus: function (el) {

      el.preventDefault();

      let count = this.getCount() - this.getShipment();
      if (count < 1) return;
      this.setCount(count);

      this.onChange();

// window.refreshLocation();
      return this;

    }, // onMinus

    onPlus: function (el) {

      console.log('onPlus')

      el.preventDefault();

      let count = this.getCount() + this.getShipment();
      this.setCount(count);

      this.onChange();

      // this.sendRequest(
      //   {
      //     item_id: this.datas.itemId,
      //     count: count
      //   },
      //   '/cart_items',
      //   'PATCH'
      // );
// window.refreshLocation();
      return this;

    }, // onPlus

    onChange: function () {

      this.t && clearTimeout(this.t);

      if (this.getCount() < 1) return;

      let self = this;
      this.t = setTimeout(function () {

        self.onResetStatus();
        self.onSend();

      }, 300);

      return this;

    }, // onChange

    getShipment: function () {

      var v = parseInt(this.datas['shipment'], 10) || 1;
      return (v < 1 ? 1 : v);

    }, // getShipment

    onCalculateCount: function (count, shipment) {

      if (count <= 0) {
        return 0;
      }

      if (shipment <= 1) {
        return count;
      }

      let arr = [
        Math.floor(count / shipment),
        count % shipment
      ];

      return (arr[0] * shipment + (arr[1] == 0 ? 0 : shipment));

    }, // onCalculateCount

    getCount: function () {

      let v = parseInt(this.countEl.val(), 10) || 0;
      return (v < 0 ? 0 : v);

    }, // getCount

    setCount: function (count) {

      this.countEl.val(
        this.onCalculateCount(count, this.getShipment())
      );
      return this;

    }, // setCount

    onDelete: function (el) {

      el && el.preventDefault();

      this.sendRequest(
        this.getValues(),
        '/cart_items',
        'DELETE'
      );

      return this;

    }, // onDelete

    onMoveItemBasket: function (el) {

      el && el.preventDefault();

      let vals = this.getValues();
      let order_id = $(el.target).data('order-id');

      // Если текущая корзина совпадает с выюранной -- завершаем работу
      if (this.getOrderId() == order_id) {
        return this;
      }

      // Иначе, меняем активную
      vals['order_id'] = order_id;

      this.sendRequest(
        {
          item: vals
        },
        '/cart_items_move',
        'PATCH'
      );

      return this;

    }, // onMoveItemBasket

    onCreateAndApplyBasket: function (el) {

      el && el.preventDefault();

      this.getWcb().reset();
      this.getWcb().save = this.onCreate.bind(this);
      this.getWcb().show();

      return this;

    }, // onCreateAndApplyBasket

    onCreate: function () {

      let vals = Object.assign({},
        this.getValues(),
        this.getWcb().values()
      );

      this.sendRequest(
        {
          order: {
            title: vals.title,
          },
          item: {
            count: vals.count,
            item_id: vals.item_id
          }
        },
        '/orders/create_manage_active',
        'POST'
      );

    }, // onCreate

    onDisable: function () {

      this.buttonCart.attr("disabled", true);
      this.buttonCart.addClass("disabled");
      this.disabled = true;
      return this;

    }, // onDisable

    onEnable: function () {

      this.buttonCart.attr("disabled", false);
      this.buttonCart.removeClass("disabled");
      this.disabled = false;
      return this;

    }, // onEnable

    onComplete: function () {

      console.log('onComplete')
      this.onEnable();
      return this;

    }, // onComplete

    onSuccess: function (r, s, xhr) {

      console.log('onSuccess');

      let cart_item_id = r.response.cart_item_id;
      let item_uid = r.response.item_uid;
      $(`*[data-item-id="${item_uid}"]`).data('cart-item-id', cart_item_id);

      $(document).trigger($.Event("basket.update"), r.response);

      this.getWcb().hide();
      this.updateBasketState(r.response);
      return this;

    }, // onSuccess

    onFailure: function (xhr, s, r) {

      if (xhr.status == 404) {
        this.getWcb().markErrors({ base: 'Ресурс не найден' })
      } else if (xhr.status == 400) {
        this.getWcb().markErrors(xhr.responseJSON.errors);
      } else {
        this.getWcb().markErrors({ base: 'На сервере возникли ошибки' })
      }

      return this;

    }, // onFailure

    getValues: function () {
      return {
        'item_id': this.datas['itemId'],
        'count': this.getCount(),
        'id': this.getOrderId(),
        'cart_item_id': this.datas['cartItemId'],
        'info': this.datas['info'],
        'source_store_uid': this.datas['source_store_uid'],
        'provider_uid': this.datas['provider_uid']
      }

    }, // getValues

    onSend: function (el) { ///

      el && el.preventDefault();
      this.t && clearTimeout(this.t);

      this.sendRequest(
        {
          item: this.getValues()
        },
        '/cart_items',
        'PATCH'
      );

      return this;

    }, // onSend

    sendRequest: function (values, url, method) {

      if (this.disabled === true) { return this; }

      this.onDisable();

      $.ajax({

        url: url,
        type: method,
        dataType: "json",
        timeout: this.timeout * 1000,
        data: values,

        context: this,

        complete: this.onComplete,
        success: this.onSuccess,
        error: this.onFailure

      });

      return this;

    },

    isChanged: function () {
      return (this.countBefore != this.getCount());
    }, // isChanged

    updateBasketState: function (res) {

      let count = parseInt(res.count, 10) || 0;
      let bsk = this.el.find('[attr="basket"]');

      bsk.data('count', count);
      bsk.find('span.slideExpandUp').text(count);

      this.setCount(count);

      this.countBefore = count;

      if (!res.destroyed) {

        this.onStatusAdded();

        let lnk = this.el.find('div.del_from_cart_link');
        if (lnk.length == 0) {
          bsk.after(DELETE_LINK_TMPL)
        }

        this.onBasketsList(res);
        this.refreshDropdownTrigger();

      } else {

        this.setCount(0);
        this.el.find('div.del_from_cart_link').remove();
        this.onResetStatus();
        this.basketItem.html('');

      }

      return this;

    }, // updateBasketState

    refreshDropdownTrigger: function () {

      M.Dropdown.init(this.basketItem.find('.dropdown-trigger'), {
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        coverTrigger: false,
        alignment: 'left',
        autoTrigger: false,
        autoFocus: false
      });

      return this;

    }, // refreshDropdownTrigger

    onBasketsList: function (res) {

      let list = this.createBasketsList(res.baskets);
      let show = res.baskets.length > 1;

      this.onRefreshAllBasketsList(list, show);

      this.basketItem.html(

        String.format(ITEM_BASKET_TMPL, {
          cls: show ? '' : 'hide',
          order_id: res.current_basket.order_id,
          title: res.current_basket.title,
          list_id: M.guid(),
          list: list,
          color: res.current_basket.color
        })

      );

      return this;

    }, // onBasketsList

    createBasketsList: function (baskets) {

      let list = '';
      let i = 0,
        l = baskets.length,
        el;

      // Собираем список доступных корзин
      for (; i < l; i++) {

        el = baskets[i];
        list += (String.format(BASKETS_EL_TMPL, {
          order_id: el[0],
          title: el[1]
        }));

      }

      // Добавляем "хвост"
      list += BASKETS_EL_CONST_TMPL;

      return list;

    }, // createBasketsList

    onRefreshAllBasketsList: function (list, show) {

      $('[attr="basket-item"]').
        find('[attr="user-baskets-list"]').
        html(list);

      if (show) {
        $('[attr="basket-item"] > div').removeClass('hide');
      } else {
        $('[attr="basket-item"] > div').addClass('hide');
      }

      return this;

    } // onRefreshAllBasketsList

  }; // Basket.prototype

  /*
   * Класс по обновлению общего счетчика корзины
   */
  let BasketPriceCounter = function (el) {
    this.render(el);
  }

  BasketPriceCounter.prototype = {

    render: function (el) {

      if (!this.el) {

        this.el = el;
        this.priceEl = this.el.find('span.cart_txt, span.price');
        this.countEl = this.el.find('span.cart_count, span.cart_counter');

        this.onEvents();

      }

      return this;

    }, // render

    onEvents: function () {

      if (this.el) {
        $(document).on('basket.update', $.proxy(this.onUpdate, this));
      }

    }, // onEvents

    onUpdate: function (evt, r) {

      if (r) {

        this.priceEl.text(r.amount);
        this.countEl.text(r.total)

      }

      return this;

    } // onUpdate

  }; // BasketPriceCounter.prototype


  /*
   * Отображение модального окна товара с добавлением в корзину и израбнным
   */
  $.fn.buttonModalCart = function (selector) {

    new ButtonModalCart(selector);
    return this;

  }

  /*
   * Управления товаром:
   * - добавление, удаление из корзины
   * - созданире новой корзины
   * - перемещение в новую корзину
   */
  $.fn.basket = function () {

    this.each(function () {
      new Basket($(this));
    });
    return this;

  }

  /*
   * Обновление общего счетчика корзины
   */
  $.fn.updateBasketCounter = function () {

    this.each(function () {
      new BasketPriceCounter($(this));
    }, this);

    return this;

  }

})(jQuery);
