import CloseImg24 from '../../img/ic_close_black_24.svg';
import CloseImg18 from '../../img/ic_clear_grey_18px.svg'

  /*
    Всплывалка срабатывает 1 раз
    Колокольчик "дергается" пока не посмотрят уведомления
    При просмотре уведомления -- засчитываем просмотр
  */

  ; (function ($) {

    'use strict'

    let ALERT_EL_TMPL = [
      '<div class="notification_alert">',
      '<div class="notification_popup__item">',
      '{img}',
      '<div class="right_block">',
      '<div class="font_bold {cls}">{title}</div>',
      '<div class="description">{content}</div>',
      '<div class="date">{date}</div>',
      '</div>',
      '<img src="', CloseImg24, '" data-alert-notice-id="{id}" class="close_btn" />',
      '</div>',
      '</div>'
    ].join('');

    let PANEL_EL_TMPL = [
      '<div class="{notice_cls}">',
      '{img}',
      '<div class="right_block">',
      '<div class="font_bold {cls}">{title}</div>',
      '<div class="description">{content}</div>',
      '<div class="date">{date}</div>',
      '</div>',
      '</div>'
    ].join('')

    let PANEL_MOBY_EL_TMPL = [
      '<div class="notification_item">',
      '{img}',
      '<div class="right_block">',
      '<div class="font_bold {cls}">{title}</div>',
      '<div class="description">{content}</div>',
      '<div class="date">{date}</div>',
      '</div>',
      //      '<img src="', CloseImg18, '" data-alert-notice-id="{id}" class="close_btn">',
      '</div>'
    ].join('');

    let PANEL_IMG_TMPL = [
      '<div class="img_block"><img src="{url}"></div>'
    ].join('');

    // let NO_CONTENT_TMPL = '<div class="no_content">Новых уведомлений нет</div>';
    let NO_CONTENT_TMPL = '<div class="no_content">Загрузка...</div>';

    let LINK_TMPL = '<a href="{link}">{content}</a>';

    var NoticeBlock = function (el) {

      // Массив id-уведомлений для фиксации "просмотра"
      this.idsNoticesForCheck = [];

      // Список id показанных алертов
      this.alertShowedIds = {};

      // Рендер
      this.render(el);

      // Чуть после вызываем
      this.start(2000);

    };

    NoticeBlock.prototype = {

      wait: 10,
      timeout: 10,
      alertWait: 10,
      url: '/notices/new',
      checkUrl: '/notices/visit',
      method: 'GET',

      render: function (el) {

        if (!this.el) {

          this.el = el;
          this.label = this.el.find('.notification_counter');
          this.counter = this.el.find('.counter');
          this.popup = this.el.find('.notification_popup_main');

          this.alert_block = this.el.find('.desktop_pop .notification_alert_block');
          this.body_desktop = this.el.find('.notification_popup_main .notification_popup__items');
          this.body_mobile = $('#modal_notification .notification_list');

          this.onEvents();

        }

        return this;

      }, // render

      onEvents: function () {

        this.el.
          on('click touchstart',
            'div.notification_counter',
            this.onShowPanel.bind(this)
          );

        this.el.
          on('click touchstart',
            'img[data-alert-notice-id]',
            this.onClickAlert.bind(this)
          );

        this.el.
          on('click touchstart',
            'a.notification_counter',
            this.onShowModal.bind(this)
          );

        $(window).click(this.closeAll.bind(this));
        this.el.click(function (evt) {
          evt.stopPropagation();
        });

      }, // onEvents

      onStart: function () {

        if (this.pending === true) { return this; }

        this.pending = true;

        $.ajax({
          url: this.url,
          type: this.method,
          dataType: "json",
          timeout: this.timeout * 1000,

          context: this,

          complete: this.onComplete,
          success: this.onSuccess,
          error: this.onFailure

        });

      }, //

      start: function (w) {

        if (!w) {
          w = this.wait * 1000;
        }

        this.t && clearTimeout(this.t);
        this.t = setTimeout(this.onStart.bind(this), w);

        return this;

      }, // start

      onComplete: function () {

        this.pending = false;

        // this.start();
        return this;

      }, // onComplete

      onSuccess: function (r, s, xhr) {

        let resp = r['response'] || {};

        // Обновляем счетчик
        this.updateCounter(parseInt(resp['total'], 10) || 0);

        // Очищаем содержимое панели уведомлений перед началом
        this.clearPanel();

        // Добавляем уведомления.
        this.addNotices(resp['notices'] || [])

        // Показываем всплывающие уведомления
        this.showAlerts();

        // cчитаем что все прочитано
        setTimeout(this.onCheck, 5000, this.idsNoticesForCheck);

        // Через заданное время закрываем всплывающие окна
        setTimeout(this.removeAlerts.bind(this), this.alertWait * 1000);

        return this;

      }, //

      onFailure: function () { }, // onFailure

      onShowModal: function (evt) {

        evt.stopPropagation();

        let el = M.Modal.getInstance(
          document.querySelector('#modal_notification')
        );
        el && el.open();

        return false;

      }, // onShowModal

      updateCounter: function (c) {

        if (c > 0) {


          this.label.addClass('new_notify');
          this.counter.text(c > 100 ? '99+' : c);

        } else {

          this.label.removeClass('new_notify');
          this.counter.text('0');

        }

        return this;

      }, // updateCounter

      onShowPanel: function () {
        
        if (this.popup.css('visibility') == 'hidden') {

          //
          // Открываем панель с уведомлениями
          //
          
          this.alert_block.css('visibility', 'hidden');

          this.popup.css('visibility', 'visible');
          this.popup.animate({ 'opacity': 1 }, 500);

          this.alert_block.animate({
            'top': 0
          }, 500);

          // Фиксируем то что пользователь увидел данные сообщения
          setTimeout(this.onCheck, 2000, this.idsNoticesForCheck)

        } else {

          //
          // Закрываем панель
          //
          this.alert_block.animate({
            'top': -this.blockHC()
          }, 500);

          this.popup.animate({
            'opacity': 0
          }, 500, function () {
            this.popup.css('visibility', 'hidden');
          }.bind(this));

        }

        return this;

      }, // onShowPanel

      blockHC: function () {
        return this.popup.find('.notification_popup__items').height() + 63;
      },

      addNotices: function (notices) {

        let i = 0,
          s = notices.length,
          n;

        this.idsNoticesForCheck = [];

        for (; i < s; i++) {

          n = notices[i];

          // Создаем в панеле уведомлений записи
          this.createPanelEl(n);

          if (n.visited != true) {

            // Создаем всплывающее сообщение
            this.createAlert(n);

            // Все уведомления, которые пользватель не увидел -- собираем в спец переменную
            this.idsNoticesForCheck.push(n.id);

          }

        }

        return this;

      }, // addNotices

      showAlerts: function () {

        if (this.popup.css('visibility') == 'hidden') {

          this.alert_block.animate({
            'top': -this.blockHC()
          }, 500);

        } else {

          this.alert_block.animate({
            'top': 0
          }, 500);

        }

        return this;

      }, // showAlerts

      createAlert: function (o) {

        if (this.alertShowedIds[o.id]) {
          return this;
        } else {
          this.alertShowedIds[o.id] = 1;
        }

        $(
          String.format(ALERT_EL_TMPL, {
            img: this.onAddImgTml(o),
            cls: o.css,
            title: o.title,
            content: this.prepareContent(o),
            date: o.date,
            id: o.id
          })
        ).appendTo(
          this.alert_block
        );

        return this;

      }, // createAlert

      onAddImgTml: function (o) {

        if (o.image) {

          return String.format(PANEL_IMG_TMPL, {
            url: o.image
          })

        } else {
          return '';
        }

      }, // onAddImgTml

      onClickAlert: function (evt) {

        evt.stopPropagation();

        this.onRemoveAlerts(
          $(evt.currentTarget).parents('.notification_alert')
        );
        return this;

      }, // onClickAlert

      removeAlerts: function () {

        this.onRemoveAlerts(
          this.el.find('.notification_alert')
        );
        return this;

      }, // onRemoveAlert

      onRemoveAlerts: function (block) {

        block.animate({
          'opacity': 0
        }, 500, function () {

          block.remove();
          this.showAlerts();

        }.bind(this));

        return this;

      }, // onRemoveAlerts

      onCheck: function (ids) {

        if (ids.length == 0) { return this; }

        $.ajax({
          type: "POST",
          url: '/notices/visit',
          data: {
            ids: ids
          },
          dataType: "json",
          context: this,
          success: function () {

            this.t && clearTimeout(this.t);
            this.onStart;

          },
          timeout: this.timeout * 1000
        });

        return this;

      }, // onCheck

      createPanelEl: function (o) {

        this.body_desktop.find('.no_content').remove();
        this.body_mobile.find('.no_content').remove();

        let notice_cls = o.visited ? 'notification_popup__item' : 'notification_popup__item greybgcolor';

        // Добавляем элемент в общую панель
        $(
          String.format(PANEL_EL_TMPL, {
            notice_cls: notice_cls,
            img: this.onAddImgTml(o),
            cls: o.css,
            title: o.title,
            content: this.prepareContent(o),
            date: o.date,
            id: o.id
          })
        ).appendTo(
          this.body_desktop
        );

        // Добавляем элемент в мобильную панель
        $(
          String.format(PANEL_MOBY_EL_TMPL, {
            img: this.onAddImgTml(o),
            cls: o.css,
            title: o.title,
            content: this.prepareContent(o),
            date: o.date,
            id: o.id
          })
        ).appendTo(
          this.body_mobile
        );

        return this;

      }, // createPanelEl

      clearPanel: function () {

        this.body_desktop.html(NO_CONTENT_TMPL);
        this.body_mobile.html(NO_CONTENT_TMPL)
        return this;

      }, // clearPanel

      prepareContent: function (o) {

        let l = String(o.link).length;
        if (l > 0) {

          return String.format(LINK_TMPL, {
            link: o.link,
            content: o.content
          });

        } else {
          return o.content;
        }

      }, // prepareContent

      closeAll: function () {

        this.onRemoveAlerts(
          this.el.find('.notification_alert')
        );

        this.popup.animate({
          'opacity': 0
        }, 500, function () {
          this.popup.css('visibility', 'hidden');
        }.bind(this));

        return this;

      } // closeAll

    }; // NoticeBlock

    /**
     * Уведомления
     */
    $.fn.notifications = function (opts) {

      this.each(function () {
        new NoticeBlock($(this));
      });

      return this;

    }; // notifications

  })(jQuery);