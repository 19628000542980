export { FormModal };

let FormModal = function(tmpl) {
  this.render(tmpl);
};

FormModal.prototype = {

  render: function(tmpl) {

    this.el     = $(tmpl).appendTo('body');
    this.alert  = this.el.find('div.alert');

    this.onRender();

    this.modal  = M.Modal.init(this.el[0], {
      opacity:  '0.9'
    });

    this.onEvents();

  }, // render

  onRender: function() {},

  show: function() {

    this.modal.open();
    return this

  }, // show

  hide: function() {

    this.modal.close();
    return this

  }, // hide

  load: function(params) {

    for(let key in params) {

      if (params.hasOwnProperty(key)) {

        this.el.find('input[name=' + key + ']').val(params[key]);
        this.el.find('label[for=' + key + ']').addClass('active');

      }

    }

    return this;

  }, // load

  values: function() {

    let h = {};
    this.el.find('input').each(function() {

      let inp = $(this);
      h[inp.attr('name')] = inp.val();

    })

    return h;

  }, // values

  markErrors: function(hash) {

    let el, msg;
    for(let key in hash) {

      if (hash.hasOwnProperty(key)) {

        msg = hash[key][0];
        el  = this.el.find('input[name=' + key + ']');

        if (el.length > 0) {

          el.addClass('tooltipped').addClass('invalid');
          el.attr('data-tooltip', msg);

        } else {

          this.alert.text(msg);
          this.alert.removeClass('hide');

        }

      }

    }

    M.Tooltip.init(document.querySelectorAll('.tooltipped'));

    return this;

  }, // markErrors

  clearErrors: function() {

    this.el.find('label.error-label').remove();
    this.el.find('input').removeClass('tooltipped').removeClass('invalid');
    this.el.find('input').removeAttr('data-tooltip')

    this.alert.text('');
    this.alert.addClass('hide');

    return this;

  }, // clearErrors

  reset: function() {

    this.el.find('input').val('');
    this.el.find('label').removeClass('active');

    this.clearErrors();

    return this;

  }, // reset

  onEvents: function() {

    this.el.on('click', 'button[attr="save"]', this.onSave.bind(this));
    return this;

  }, // onEvents

  onSave: function() {

    this.save.call(this);
    return this;

  }, // onSave

  save: function() {
    console.log('Нужно переоределить данный метод у экземпляра')
  } // save

}; // FormModal
