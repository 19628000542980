/**
 *
 * Маленький плагин, аналог библиотеки InView
 *
 */
;(function($) {

  'use strict'

  var Base = function(selector) {
    this.render(selector);
  }; // Base

  Base.prototype = {

    prevEl: null,

    getId: function() {
      return (this.id = this.id || ("el-" + (+ new Date())));
    },

    fire: function() {

      var evt = $.Event(arguments[0] + "." + this.getId());
      this.el.trigger(evt, Array.prototype.slice.call( arguments, 1 ));
      return evt.result;

    },

    on: function(evt, fn, scope) {

      if (typeof fn == "function") {
        this.el.on(evt + "." + this.getId(), $.proxy(fn, scope || this));
      }
      return this;

    },

    render: function(selector) {

      if (!this.el) {

        this.el       = $(document);
        this.selector = selector;
        this.onEvents();

      }

      return this;

    },

    onEvents: function() {

      this.el.on(
        'load scroll resize orientationchange page:change',
        $.proxy(this.onChange, this)
      );
      return this;

    },

    onChange: function() {

      var self = this;
      $(this.selector).each(function() {

        if (self.isInView(this) && (this !== self.prevEl)) {

          self.fire('enter', this);
          self.fire('exit',  self.prevEl);
          self.prevEl = this;

        }

      });

      return this;

    },

    isInView: function(el) {

      var top     = $(el).offset().top,
          bottom  = top + $(el).height(),
          pY      = window.pageYOffset + 10;

      return ( (top < pY) && (bottom > pY) );

    }

  }; // Base.prototype

  window.sInView = function(sel) {
    return new Base(sel);
  }

})(jQuery);