;(function($) {

  'use strict'

  var Base = function(opts) {

    this.opts = opts || {};
    this.init();

  }; // Base

  Base.prototype = {

    init: function() {

      this.offset   = Math.abs(parseInt(this.opts.offset, 10) || 0);
      this.beforeS  = 0;
      this.diff     = 0;
      this.wst      = 0;

      if (typeof(this.opts.action) != 'function') {
        this.action = function() {};
      } else {
        this.action = this.opts.action;
      }

      $(window).bind('scroll resize orientationchange page:change', $.proxy(this.onScroll, this));

    }, // init

    onScroll: function() {

      if (this.isValid()) {

        this.action.call(
          this.opts.scope || window,
          (this.diff > 0 ? 'down' : 'up'),
          this.wst
        );

      }
      return this;

    }, // onScroll

    isValid: function() {

      this.wst      = $(window).scrollTop();
      this.diff     = this.wst - this.beforeS;
      this.beforeS  = this.wst;

      return ( (this.wst - this.offset) > 0 );

    }

  }; // Base.prototype

  /**
   * Действие по скроллингу
   */
  $.fn.actionByScroll = function(opts) {

    this.each(function() {
      new Base(opts);
    });

    return this;

  }; // actionByScroll

})(jQuery);
