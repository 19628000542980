import { FormModal } from './forms_modal.js';

/**
 *
 * Функционал по работе с корзинами пользователя
 */

; (function ($) {

  $('.popup_helper__trigger').on('click', function (e) {
    e.preventDefault()
    if ($(this).parent().find('.popup_helper').is(':visible')) {
      $(this).parent().find('.popup_helper').fadeOut(200)
    } else {
      $(this).parent().find('.popup_helper').fadeIn(200)
    }
  })

  $(document).mouseup(function (e) {
    var container = $('.popup_helper');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.fadeOut(200);
    }
  });

  $('.popup_helper__hover').on('mouseover', function () {
    $(this).find('.popup_helper').fadeIn(200)
  })
  $('.popup_helper__hover').on('mouseleave', function () {
    $(this).find('.popup_helper').fadeOut(200)
  })

  'use strict'

  let BASKET_FORM_TMPL = `
    <div class="modal new_modal" style="max-width: 700px;">
      <div class="modal-content">
        <h5>Дополнительная корзина</h5>
        <div>
          Подробнее о функциях мультикорзины можно узнать в разделе
          <a href="https://voshod.help/kb/cart">справки</a>
        </div>
        <br />
        <div class="input-field">
          <input id="title" name="title" type="text" class="validate" data-position="top">
          <label for="title">Название корзины</label>
        </div>
        <div class="alert"></div>
      </div>
      <div class="border"></div>
      <div class="modal-footer">
        <div class="controls">
          <button class="btn_outline_grey_big tlink modal-close">Отменить</button>
          <button attr="save" class="blue_button tlink">Применить</button>
        </div>
      </div>
    </div>
  `;

  let CART_ITEM_NOTICES_TMPL = `
    <div class="modal new_modal" style="max-width: 700px;">
      <div class="modal-content">
        <h5>Заметка</h5>
        <div>Текст заметки отображается только в вашей корзине и не отправляется вместе с заказом</div>
        <br />
        <div class="input-field">
          <input id="notices" name="notices" type="text" class="validate" data-position="top">
          <label for="notices">Текст заметки</label>
        </div>
        <div class="alert"></div>
      </div>
      <div class="border"></div>
      <div class="modal-footer">
        <div class="controls">
          <button class="btn_outline_grey_big tlink modal-close">Отменить</button>
          <button attr="save" class="blue_button tlink">Сохранить</button>
        </div>
      </div>
    </div>
  `;

  let BASKET_ITEMS_EMPTY_TMPL = `
    <div class="txt_center_msg_container">
      <div class="txt1">Ваша корзина заказа пуста</div>
      <div class="txt2">Попробуйте что-нибудь добавить в корзину или загрузите заказ из xls-файла</div>
    </div>
  `;

  let CreateNoticeModal = function (el) {
    this.init(el);
  };

  CreateNoticeModal.prototype = {
    init: function (el) {
      this.el = el;
    },
    create: function (data, text) {
      this.el.reset();
      this.el.save = this.onCreate.bind(this, data);
      this.el.show();

      if (text) {
        let $noticeEl = $(this.el)[0].el;
        $noticeEl.find('#notices').val(text);
        $noticeEl.find('label[for="notices"]').addClass('active');
      }

      return this;
    },
    onCreate: function (data) {
      this.sendRequest(
        {
          item: {
            ...data,
            ...this.el.values()
          }
        },
        `/cart_items`,
        'PATCH',
      );

      return this;
    },
    sendRequest: function (values, url, method) {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url: url,
        type: method,
        dataType: "json",
        timeout: this.timeout * 1000,

        data: values,

        context: this,

        complete: this.onComplete,
        success: this.onSuccess,
        error: this.onFailure
      });

      return this;
    },

    onSuccess: function () {
      window.location.reload();
    }
  };

  let CreateBasketModal = function (el) {
    this.init(el);
  };

  CreateBasketModal.prototype = {

    timeout: 30,

    init: function (el) {
      this.el = el;
    }, // init

    create: function () {

      this.el.reset();
      this.el.save = this.onCreate.bind(this);
      this.el.show();

      return this;

    }, // create

    edit: function (id, values) {

      this.el.reset();
      this.el.load(values);
      this.el.save = this.onEdit.bind(this, id);
      this.el.show();

      return this;

    }, // edit

    onCreate: function () {

      this.sendRequest(
        {
          order: this.el.values()
        },
        '/orders',
        'POST'
      );

      return this;

    }, // onCreate

    onEdit: function (id) {

      this.sendRequest(
        {
          order: this.el.values()
        },
        '/orders/' + id,
        'PATCH'
      );

      return this;

    }, // onEdit

    sendRequest: function (values, url, method) {

      if (this.sending === true) {
        return this;
      }

      this.sending = true;

      $.ajax({
        url: url,
        type: method,
        dataType: "json",
        timeout: this.timeout * 1000,
        data: values,
        context: this,
        complete: this.onComplete,
        success: this.onSuccess,
        error: this.onFailure
      });

      return this;

    }, // sendRequest

    onComplete: function () {

      this.sending = false;
      window.location.reload();
      return this;

    }, // onComplete

    onSuccess: function (r, s, xhr) {

      this.el.hide();

      $(document).trigger(
        $.Event("basket." + r.status),
        r);
      
      document.location.reload();

      return this;

    }, // onSuccess

    onFailure: function (xhr, s, r) {

      if (xhr.status == 404) {
        this.el.markErrors({ base: 'Ресурс не найден' })
      } else if (xhr.status == 400) {
        this.el.markErrors(xhr.responseJSON.errors);
      } else {
        this.el.markErrors({ base: 'Возникли ошибки' })
      }

      return this;

    } // onFailure

  }; // CreateBasketModal

  /*
   * Отображение модального окна для:
   * - создания новой корзины
   * - редактирование названия корзины
   */
  $.fn.titleBasketModal = function (selector) {

    new CreateBasketModal(selector);

    let win = new FormModal(BASKET_FORM_TMPL);
    let cbm = new CreateBasketModal(win);

    let win2 = new FormModal(CART_ITEM_NOTICES_TMPL);
    let cartItemNoticeModal = new CreateNoticeModal(win2);

    this.on('click', 'a[attr="edit-item"]', function (evt) {
      evt.preventDefault();

      let $target = $(evt.target);
      let $cartProduct = $target.closest('.cart_product');
      let data = {
        'order_id': $cartProduct.data('order-id'),
        'cart_item_id': $cartProduct.data('cart-item-id'),
        'item_id': $cartProduct.data('item-id')
      };
      let text = $cartProduct.find('[attr="edit-item"]').data('value');

      cartItemNoticeModal.create(data, text);
    });

    this.on('click', 'a[attr="create-basket"]', function (evt) {
      evt.preventDefault();
      cbm.create();
    });

    this.on('click', 'a[attr="edit-basket"]', function (evt) {

      evt.preventDefault();

      cbm.edit($(this).data('order-id'), {
        title: $(this).data('order-title')
      });

    });

    $(document).on('basket.create', function () {
      window.refreshLocation();
    });

    $(document).on('basket.update_title', function (evt, r) {

      $('a[data-order-id="' + r.id + '"]').data('order-title', r.title);
      $('a[data-order-id="' + r.id + '"].title').text(r.title);

    });

    return this;

  }; // titleBasketModal

  /**
   * Менеджер корзины
   */
  let BasketManager = function (el) {
    this.render(el);
  };

  BasketManager.prototype = {

    render: function (el) {

      if (!this.el) {

        this.el = el;
        this.onEvents();

      }

      return this;

    }, // render

    onEvents: function () {

      this.el.on('click', 'a[attr="delete-item"]', this.onDeleteItem.bind(this));
      this.el.on('click', 'a[attr="set-basket"]', this.onSetBasketItem.bind(this));

      this.el.on('click', 'a[attr="plus_count_item"]', this.onChangeItemCount.bind(this, 1));
      this.el.on('click', 'a[attr="minus_count_item"]', this.onChangeItemCount.bind(this, -1));

      this.el.on('change', '[attr="order-checkbox"]', this.onSelectAll.bind(this));
      this.el.on('change', '[attr="item-checkbox"]', this.onSelect.bind(this));

      this.el.on('change', '[attr="update-comment"]', this.onUpdateComment.bind(this));
      
      // this.el.on('change', '[attr="update-delivery-pickup"]', this.onUpdateDelivery.bind(this, 0));
      // this.el.on('change', '[attr="update-delivery-address"]', this.onUpdateDelivery.bind(this, 1));
      // this.el.on('change', '[attr="update-pickup"]', this.onUpdateDelivery.bind(this, 0));
      // this.el.on('change', '[attr="update-delivery"]', this.onUpdateDelivery.bind(this, 1));
      // this.el.on('change', '[attr="update-date"]', this.onChange.bind(this));

      this.el.find('[attr="items-actions-cnt"]').find('li').on('click', this.onItemActionsSelect.bind(this));
      this.el.on('click', '[attr="confirm"]', this.onItemActionsSelect.bind(this));
      this.el.on('keyup', '[attr="count"]', this.onChange.bind(this));

      return this;

    }, // onEvents

    onChange: function (evt) {
      let $target = $(evt.target).closest('.cart_product');
      this.t && clearTimeout(this.t);

      let self = this;
      this.t = setTimeout(function () {
        self.onSend($target);
      }, 300);

      return this;
    },

    onSend: function ($target) {
      this.t && clearTimeout(this.t);

      let $dataEl = $target;
      let $countEl = $dataEl.find('[attr="count"]');
      let $totalPriceEl = $dataEl.find('[attr="total_price"]');
      let $cart = $target.closest('.cart');
      let $cartTotalPrice = $cart.find('[attr="cart_total_price"]');

      let $multicartEl = $target.closest('.multicart');
      let $orderTotalPriceEl = $multicartEl.find('[attr="order_total_price"]');

      let newCount = this.getCount($countEl);
      if (newCount < 1) return;

      let price = parseFloat($dataEl.attr('data-price').replace(/ /g, ''));
      let newPrice = price * newCount;

      let onSuccess = function (response, status, xhr) {
        $totalPriceEl.html(newPrice.toFixed(2));
        $cartTotalPrice.html(response.response.current_basket.total_price.toFixed(2));
        $orderTotalPriceEl.html(response.response['amount']);
        $(document).find('[attr="order_total_price_cart"]').html(response.response['amount']);
        $(document).find('[attr="cart_items_count"]').html(response.response['cart_items_count']);
      };

      let params = this.getItemParams($target);

      this.sendRequest(
        {
          item: params,
        },
        `/cart_items`,
        'PATCH',
        onSuccess
      );

      return this;
    }, // onSend

    // onUpdateDelivery: function (evt_type, evt) {

    //   evt.preventDefault();

    //   let req_params;

    //   switch (evt_type) {
    //     case 0: 
    //       req_params = { 
    //         delivery_type: '0',
    //         delivery_address: $(this.el).find(":selected").text(),
    //         outlet: $(this.el).find(":selected").val()
    //       };
    //       break;
    //     case 1:
    //       req_params = { 
    //         delivery_type: '1',
    //         delivery_address: $(this.el).find(":selected").text(),
    //         outlet: $(this.el).find(":selected").val()
    //       };
    //       break;
    //     // case 2:
    //     //   req_params = {
    //     //     delivery_type: '1',
    //     //     delivery_address: $(this.el).find(":selected").text(),
    //     //     outlet: $(this.el).find(":selected").val()
    //     //   };
    //     //   break;
    //   };

    //   this.sendRequest(
    //     {
    //       order: req_params
    //     },
    //     `/orders/${this.currentOrderId()}`,
    //     'PATCH',
    //   );

    //   return this;
    // },

    onUpdateComment: function (evt) {
      evt.preventDefault();

      let $target = $(evt.target);

      this.sendRequest(
        {
          order: {
            comment: $target[0].value
          }
        },
        `/orders/${this.currentOrderId()}`,
        'PATCH',
      );

      return this;
    },

    onItemActionsSelect: function (evt) {
      evt.preventDefault();
      let target = $(evt.target)[0];
      let $target = $(target);
      console.log($target);
      let action = $target.data('action');

      let checked = this.el.find('[attr="item-checkbox"]').filter((idx, el) => $(el).prop('checked'));

      let data = checked.map((idx, el) => this.getItemParams($(el).closest('.cart_product')));

      let url;
      let method;
      let values;

      switch (action) {

        case 'delete':
          if (!confirm('Удалить товары из корзины?')) return;

          url = `/cart_items/destroy_multiple`;
          method = 'DELETE';
          values = {
            item_ids: data.map((idx, el) => el.item_id).toArray(),
          };
          break;

        case 'confirm':

          let order_id = $(target).closest('.cart').attr('data-order-id');

          values = data.map((idx, el) => el.cart_item_id.toString()).toArray();

          let myArrayQry = values.map(function (el, idx) {
            return 'cart_item_ids[]=' + el;
          }).join('&');
          window.location.href = `/orders/accept?order_ids=${this.currentOrderId()}&${myArrayQry}`;
          return;

        // url = `/orders/confirm`;
        // method = 'POST';
        // values = {
        //   order_ids: [order_id]
        // };
        // break;

        default:
          url = `/cart_items/update_multiple`;
          method = 'PATCH';
          values = {
            cart_item_ids: data.map((idx, el) => el.cart_item_id).toArray(),
            item: {
              order_id: $(target).data('order_id')
            }
          };
          break;
      }

      this.sendRequest(
        values,
        url,
        method,
        () => { window.location.reload() }
      );

      return this;
    },

    onChangeItemCount: function (value, evt) {

      evt.preventDefault();

      let $dataEl = $(evt.target).parents('div.cart_product');
      let $countEl = $dataEl.find('[attr="count"]');
      let $totalPriceEl = $dataEl.find('[attr="total_price"]');
      let $cart = $(evt.target).closest('.cart');
      let $cartTotalPrice = $cart.find('[attr="cart_total_price"]');

      let cart_item_id = $(evt.target).closest('.cart_product').attr('data-cart-item-id');

      let $multicartEl = $(evt.target).closest('.multicart');
      let $orderTotalPriceEl = $multicartEl.find('[attr="order_total_price"]');

      //let newCount = this.getCount($countEl) + value;
      let shipment = parseFloat($dataEl.attr('data-item-shipment'));
      // let maxcount = parseFloat($dataEl.attr('data-item-count'));

      let count = this.getCount($countEl);

      let newCount = count;

      if (value == 1) {
        newCount = this.getCount($countEl) + shipment;
      } else {
        if (count > shipment) {
          newCount = this.getCount($countEl) - shipment;
        } else {
          $(".loader").hide();
          alert('Слишком малое количество!');
          return false;
        }
      };

      // newCount = newCount < 1 ? 1 : newCount;

      $countEl.val(newCount);

      let price = parseFloat($dataEl.attr('data-price').replace(/ /g, ''));
      let newPrice = price * newCount;

      let onSuccess = function (response, status, xhr) {
        $totalPriceEl.html(newPrice.toFixed(2));
        $cartTotalPrice.html(response.response.current_basket.total_price.toFixed(2));
        $orderTotalPriceEl.html(response.response['amount']);
        $(document).find('[attr="order_total_price_cart"]').html(response.response['amount']);
        $(document).find('[attr="cart_items_count"]').html(response.response['cart_items_count']);
      };

      let values = this.getItemParams($dataEl);
            
      this.sendRequest(
        {
          item: values,
        },
        `/cart_items`,
        'PATCH',
        // onSuccess
        () => document.location.reload()
      );

      return this;

    },

    getCount: function (element) {

      let v = parseInt(element.val(), 10) || 0;
      return (v < 0 ? 0 : v);

    },

    onSelectAll: function (evt) {

      this.el.
        find('[attr="item-checkbox"]').
        prop("checked", $(evt.target).is(":checked")).
        trigger('change');

      return this;

    }, // onSelectAll

    onSelect: function (evt) {

      let ch = this.el.find('[attr="item-checkbox"]').is(":checked");
      let ct = this.el.find('[attr="items-actions-cnt"]');

      if (ch) {
        ct.removeClass('disabled');
      } else {
        ct.addClass('disabled');
      }

      return this;

    }, // onSelect

    sendRequest: function (values, url, method, onSuccess = null) {
      $(".loader").show();
      $.ajax({
        url: url,
        type: method,
        dataType: "json",
        timeout: this.timeout * 1000,
        data: values,
        context: this,
        complete: this.onComplete,
        // success: onSuccess || this.onSuccess,
        // onSuccess
        success: document.location.reload()
        // error: this.onFailure

      });

      return this;

    }, // sendRequest

    onComplete: function () { },

    onSuccess: function (r, s, xhr) {

      //      $(document).trigger(
      //        $.Event("basket." + r.status),
      //      r);

      document.location.reload();
      
      return this;

    }, // onSuccess

    onFailure: function (xhr, s, r) { },

    onDeleteItem: function (evt) {

      evt.preventDefault();

      if (!confirm('Удалить товар из корзины?')) {
        return false
      }

      let $target = $(evt.target);

      let $cart = $target.closest('.cart');
      let $cartTotalPrice = $cart.find('[attr="cart_total_price"]');

      let $multicartEl = $target.closest('.multicart');
      let $orderTotalPriceEl = $multicartEl.find('[attr="order_total_price"]');


      let onSuccess = function (response, status, xhr) {
        $cartTotalPrice.html(response.response.current_basket.total_price.toFixed(2));
        $orderTotalPriceEl.html(response.response['amount']);
        $(document).find('[attr="order_total_price_cart"]').html(response.response['amount']);
        $(document).find('[attr="cart_items_count"]').html(response.response['cart_items_count']);
      };


      let values = this.getItemParams($target.parents('div.cart_product'));
      values.count = 0;

      this.sendRequest(
        values,
        `/cart_items`,
        'DELETE',
        onSuccess
      );

      const $cartProducts = $target.closest('.cart_products');
      const $cartContent = $target.closest('.cart_content');

      $target.closest('.cart_product').remove();

      if ($cartProducts.children().length == 0) {
        $cartContent.html(BASKET_ITEMS_EMPTY_TMPL);
        $cart.find('.cart_header__middle-right').remove();
        $cart.find('.cart_header__bottom-right').remove();
      }
      
      $(".loader").show();
      
      document.location.reload();
      
      return this;

    }, // onDeleteItem

    onSetBasketItem: function (evt) {

      evt.preventDefault();

      let item = $(evt.target);

      // Выбираем id новой корзины (для перемещения)
      let new_oid = parseInt(item.data('order-id'), 10) || 0;

      // Выбираем id текущей корзины
      let curr_oid = this.currentOrderId();

      // Если id-шники не совпадают -- формируем даныне и отправляем запрос
      if (new_oid != curr_oid) {

        let values = this.getItemParams(item.parents('div.cart_product'));
        values['order_id'] = new_oid;

        this.sendRequest(
          {
            item: values
          },
          '/cart_items_move',
          'PATCH',
          () => document.location.reload()
        );

      }

      return this;

    }, // onSetBasketItem

    getItemParams: function (item) {

      return {
        'item_id': item.data('item-id'),
        'oem_num': item.data('oem-num'),
        'oem_brand': item.data('oem-brand'),
        'mog': item.data('mog'),
        'order_id': item.data('order-id'),
        'count': this.getCount(item.find('[attr="count"]')),
        'full_update': true,
        'cart_item_id': item.data('cart-item-id'),
      }

    }, // getItemParams

    currentOrderId: function () {
      return parseInt(this.el.data('order-id'), 10) || 0;
    } // currentOrderId

  }; // BasketManager

  /**
   * Изменение кол-ва товара в корзине, перемещение товара из корзины в корзину,
   * удаление товара (товаров) из корзины, заметки к товару.
   * Комментарий к корзине, тип и адрес доставки.
   */
  $.fn.basketsManager = function () {

    this.each(function () {
      new BasketManager($(this));
    });
    return this;

  }; // basketsManager

})(jQuery);
