import { FormModal } from "./forms_modal";

; (function ($) {

  let GarageModal = function (el) {
    this.init(el);
  };

  GarageModal.prototype = {
    init: function (el) {
      this.el = el;
    },
    create: function () {
      this.el.reset();
      this.el.save = this.onCreate.bind(this);
      this.el.show();

      $(document).find('.garage_modal__item').on('click', this.setVinSearch.bind(this));

      return this;
    },
    onCreate: function () {

    },
    setVinSearch: function (evt) {
      let $target = $(evt.target);
      let $vehicleItem = $target.closest('.garage_modal__item');
      let vin = $vehicleItem.data('vin');
      let ssd = $vehicleItem.data('ssd');
      let vc = $vehicleItem.data('catalog');
      let vehicle_id = $vehicleItem.data('vehicle_id');

      window.location.href = `/vsearch?q=${vin}&t=v&ssd=${ssd}&v=${vehicle_id}&vc=${vc}`;
    }
  };


  let MaintenanceManager = function (el) {
    this.init(el);
  };

  MaintenanceManager.prototype = {
    init: function (el) {
      this.el = el;

      let win = new FormModal($(el).find('#modal_add_car'));
      let modal = new GarageModal(win);

      this.garageButton = $(this.el).find('#selectFromGarage');
      this.garageButton.on('click', () => modal.create());
    },
  };

  $.fn.maintenanceManager = function () {
    this.each(function () {
      new MaintenanceManager($(this));
    });
    return this;
  };

})(jQuery);
