;(function($) {

  'use strict';
  let GarageManager = function(el) {
    this.init(el);
  };

  GarageManager.prototype = {
    init: function(el) {
      this.el = el;
      this.searchEl = $(el).find('[attr="search"]');

      this.searchEl.on('click', this.onSearchClick.bind(this));

      //copy btn
      $('.garage_content__item-right .vin_txt .copy').on('click', function () {
        var $tmp = $("<input>");
        $("body").append($tmp);
        $tmp.val($(this).parent().find('span').text()).select();
        document.execCommand('copy');
        $tmp.remove();
      });
    },
    onSearchClick: function (evt) {
      evt.preventDefault();
      let val = $(this.el).find('input#cart_name').val();

      $.ajax({
        url:      '/vehicles/search',
        type:     'GET',
        data:     {
          text: val,
        },
        context:  this,
        success: function(r, s, xhr) {
          $(this.el).find('#item').html(r);
        },
        error: function(error) {
          console.log(error);
        }
      });
    }
  };

  $.fn.garageManager = function() {
    this.each(function() {
      new GarageManager($(this));
    });
    return this;
  };

})(jQuery);
