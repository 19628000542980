;(function($) {

  'use strict'

  var FavoriteItemUpdater = function(el, clb) {

    this.render(el);
    this.callback = (typeof(clb) == 'function' ? clb : function() {});

  }; // FavoriteItemUpdater

  FavoriteItemUpdater.prototype = {

    timeout:    50,
    cls_fav:    'active',

    render: function(el) {

      if (!this.el) {
        this.el = el;
      }

      return this;

    }, // render

    getUrl: function() {
      return this.el.attr('href');
    },

    getMethod: function() {
      return 'patch';
    },

    submit: function() {

      if (this.disabled === true) { return this; }

      this.disabled = true;

      $.ajax({
        url:      this.getUrl(),
        type:     this.getMethod(),
        dataType: "json",
        timeout:  this.timeout*1000,

        context:  this,

        complete: this.onComplete,
        success:  this.onSuccess,
        error:    this.onFailure

      });

      return this;

    }, // onSubmit

    onComplete: function() {

      this.disabled = false;
      return this;

    }, // onComplete

    onSuccess: function(r, s, xhr) {

      if (r.added == true) {
        this.setFavorite();
      } else {
        this.setDefault();
      }

      return this;

    }, // onSuccess

    onFailure: function(r, s, xhr) {
      return this;
    }, // onFailure

    setFavorite: function() {

      this.el.addClass(this.cls_fav);
      this.callback.call(null, 'add', this.el);
      return this;

    }, // setFavorite

    setDefault: function() {

      this.el.removeClass(this.cls_fav);
      this.callback.call(null, 'remove', this.el);
      return this;

    } // setDefault

  }; // FavoriteItemUpdater.prototype

  /*
   * Обработка кнопки "добавить в избранное"
   */
  $.fn.favoriteItemUpdater = function(selector, fn) {

    this.on('click', selector, function(evt) {

      evt.preventDefault();

      var t = evt.currentTarget || evt.target;

      if (!t.f) {
        t.f = new FavoriteItemUpdater($(t), fn);
      }

      t.f.submit();

    });

    return this;

  }; // favoriteItemUpdater

})(jQuery);
