;(function($) {

  'use strict'

  var Base = function(el, offset) {

    this.dir_before = '';
    this.offset     = offset;

    this.render(el);

  };

  Base.prototype = {

    animClassDown: 'up',
    animClassUp:   'down',

    render: function(el) {

      if (!this.el) {

        this.el = el;
        this.onEvent();

      }

      return this;

    },

    onEvent: function() {

      this.el.actionByScroll({

        offset: this.offset,
        action: this.onAction,
        scope:  this

      });

      return this;

    },

    onAction: function(dir, wst) {

      if (this.dir_before != dir) {

        if (dir == 'down') {
          this.onUp();
        } else {
          this.onDown();
        }

        this.dir_before = dir;

      }

      return this;

    },

    onUp: function() {

      if (this.el) {
        this.el.removeClass(this.animClassUp);
        this.el.addClass(this.animClassDown);
      }

      return this;

    },

    onDown: function() {

      if (this.el) {
        this.el.removeClass(this.animClassDown);
        this.el.addClass(this.animClassUp);
      }

      return this;

    }

  };

  $.fn.stickyHead = function(offset) {

    this.each(function() {
      new Base($(this), offset);
    });
    return this;

  }; // stickyHead

})(jQuery);
