;(function($) {

  'use strict'

  var Base = function(el, name) {

    this.name = name;
    this.render(el);

  };

  Base.prototype = {

    class: 'active',

    render: function(el) {

      if (this.el) { return this; }

      this.el = el;
      this.a  = el.find('a');
      this.onEvents();

      return this;

    },

    onEvents: function() {

      if (!this.el) { return this; }

      this.a.on('click', $.proxy(this.onToggle, this));

      return this;

    },

    onToggle: function(evt) {

      evt.preventDefault();

      var target = $(evt.target);

      if (target.hasClass(this.class)) {
        target.removeClass(this.class);
      } else {
        target.addClass(this.class);
      }

      this.onDelete();
      this.onValues();

    },

    onDelete: function() {

      this.el.find('input[type=hidden]').remove();
      return this;

    },

    onValues: function() {

      var self = this;
      self.el.find('a.active').each(function() {

        var el = $('<input type="hidden" name="' + self.name + '"/>');
        el.val($(this).text());
        self.el.append(el);

      })

      return this;

    }

  }; // Base.prototype

  $.fn.inputMultySelect = function(name) {

    this.each(function() {
      new Base( $(this), name );
    });
    return this;

  }; // inputMultySelect

})(jQuery);
