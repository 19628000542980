import './vendor/relevant-dropdown';
import './vendor/jquery.sticky-sidebar';
import './vendor/jquery.imagemapster';
import './vendor/jquery.scrollTo';
import './vendor/jquery.mousewheel';
import noUiSlider from './vendor/nouislider.min'

import './usr/lib';
import './usr/referrer';
import './usr/simple_in_view';
import './usr/action_by_scroll';
import './usr/sticky_head';
import './usr/input_multy_select';
import './usr/favorite';
import './usr/item';
import './usr/orders';
import './usr/ajax_filter_form';
import './usr/order_history';
import './usr/autocomplete';
import './usr/adv';
import './usr/notices';
import './usr/notices_settings';
import './usr/markups';
import './usr/garage';
import './usr/maintenance';
// import UsrSnowEffect from './usr/snow';

function updateSliders() {
  // range slider
  try {

    let properties = $('.advanced_search_full_container > .advanced_search_filter').data('properties');
    properties = properties.concat(properties.map((e) => e + '-modal'));

    properties.forEach((el) => {
      let $el = $('#property-' + el);
      let slider = $el.find('#range-slider')[0];
      let range1 = $el.find('.range1');
      let range2 = $el.find('.range2');

      noUiSlider.create(slider, {
        start: [$el.data('min'), $el.data('max') + 1],
        tooltips: false,
        connect: true,
        step: 1,
        orientation: 'horizontal',
        range: {
          'min': $el.data('min'),
          'max': $el.data('max') + 1
        },
        format: wNumb({
          decimals: 0
        })
      });

      let $minInput = $el.find('[attr="min"]');
      let $maxInput = $el.find('[attr="max"]');

      if ($minInput.val() && $maxInput.val()) {
        slider.noUiSlider.set([$minInput.val(), $maxInput.val()]);
      }

      slider.noUiSlider.on('update', function (values) {
        let min = Math.round(values[0]);
        let max = Math.round(values[1]);
        $(range1).text(min);
        $(range2).text(max);
        $minInput.val(min);
        $maxInput.val(max);
      });

      slider.noUiSlider.on('change', function (values) {
        $minInput.trigger('change');
      });
    });
  }
  catch (e) {
  }
}

; (function ($) {

  $(document).on('ready page:change', function () {
    $(".loader").hide();

    updateSliders();

    M.Sidenav.init(document.querySelectorAll('.sidenav'), {
      menuWidth: 300,
      edge: 'left',
      closeOnClick: true,
      draggable: true,
    });

    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
      inDuration: 300,
      outDuration: 225,
      constrainWidth: false,
      coverTrigger: false,
      alignment: 'left',
      autoTrigger: false,
      autoFocus: false,
      closeOnClick: false
    });

    M.FormSelect.init(document.querySelectorAll('select'), {});

    // Мультиселект
    $('.multy-select').inputMultySelect('b[]');
    $('.multy-select-stores').inputMultySelect('stores[]');

    // Работа с корзиной заказа в общем списке товаров (каталоги, поиск)
    $('main div.product-modal-cart').basket();

    //  $('.search_result_table_col_filter, .sidebar_col').stickySidebar({
    //    topSpacing: 0
    //  });

  });

  $(document).on('ready page:change', function () {
    // updateSliders();

    $.ajaxSetup({

      cache: true,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }

    });

    // Disable the default browser action for file drops on the document
    $(document).bind('drop dragover', function (e) {
      e.preventDefault();
    });

    M.Modal.init(document.querySelectorAll('.modal'), {
      opacity: '0.9'
    });

    // Кнопка поиска в мобильной версии
    $('.search_button').click(function (e) {
      $(e.target).parents('form').submit();
    });

    $('#footer_nooferta_link').click(function (e) {

      $(".footer_nooferta_container").toggleClass('active');
      e.preventDefault();

    });

    // "Липкая" шапка
    $('header').stickyHead(50);

    $('div.notifi_container').each(function () {

      var el = $(this);
      el.on('click', 'a.btn_close', function (evt) {

        evt.preventDefault();
        el.remove();

      });

    });

    // Добавление в избранное
    $('main').favoriteItemUpdater('a[attr="favorite"]', function (act, el) {
      el.data('fav', (act == 'add' ? '1' : '0'));
    });

    // Загрузка данных через ajax при изменениях в фильтре
    $('main').ajaxFilterForm('div.advanced_search_filter');

    // Раскрытие/скрытие истории изменений заказа
    $('.order-info-history').toggleOrderHistory();

    $('button[data-link]').click(function (evt) {
      // exclude rec act click
      if (evt.target.className != 'rec_act dashed_link_grey') {
        window.location = $(this).data('link');
      }
    })

    $('input.drop-down-plugin').relevantDropdown();

    $('[data-focus]').focus(function () {

      var el = $(this).data('focus');
      $(el).click();

    });

    M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});

    M.Datepicker.init(document.querySelectorAll('.datepicker'), {

      format: 'dd-mm-yyyy',
      firstDay: 1,
      i18n: {
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthsShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
        weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        weekdaysAbbrev: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        today: 'сегодня',
        clear: 'удалить',
        cancel: 'отменить',
        close: 'закрыть',
        done: 'выбрать'
      },

      onClose: function () {

        var el = $(this.el);
        if (el.data('action') == 'send') {
          $(".loader").show();
          el.parents('form').submit();
        }

      }

    });

    $('div.mobile_search_button').click(function () {
      $(this).parents('form').submit();
    });

    // Переключение между табами в каталогах ТО
    $('.car_list__tabs .tab').click(function (event) {
      $('.car_list__tabs .tab').removeClass('active');
      $('.car_list').css('display', 'none');
      $(this).addClass('active');
    })
    $('.car_list__tabs').on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $($(this).attr('href')).removeAttr('style');
    });

    $('.car_list__tabs .tab').first().trigger('click');

    //
    // Функционал по работе с картой деталей
    //
    var map = $('img[usemap]').mapster({

      mapKey: 'data-state',
      fillColor: 'ff0000',
      fillOpacity: 0.3,
      stroke: true,

      strokeColor: 'ff0000',
      strokeOpacity: 0.3,

      singleSelect: true,
      clickNavigate: false,
      scaleMap: true,

      showToolTip: true,
      mapValue: 'alt',

      onClick: function (data) {

        var unit = $(data.e.target).data('unit');
        var el = $('a[data-unit=' + unit + ']');

        $('a[data-unit]').data('active', '');
        $('a[data-unit]').removeClass('active');

        el.addClass('active');
        el.data('active', '1');

        $.scrollTo($('a[data-unit=' + unit + ']'), 350, {
          offset: { top: -250, left: 0 }
        });

      }, // onClick

      onMouseover: function (data) {

        var unit = $(data.e.target).data('unit');
        $('a[data-unit=' + unit + ']').addClass('active');

      },

      onMouseout: function (data) {

        var unit = $(data.e.target).data('unit');
        var el = $('a[data-unit=' + unit + ']');

        if (el.data('active') != '1') {
          el.removeClass('active');
        }

      }

    }); // mapster

    $('div.search-type-vin div.part_block').each(function () {

      var me = $(this);
      var onSelected = function () {
        return me.find('a.part_block__item').data('unit');
      };

      $(this).find('a.part_block__item').hover(
        function () {
          map.mapster('set', false, onSelected());
          map.mapster('highlight', $(this).data('unit'));
        },
        function () {
          map.mapster('set', true, onSelected());
        }
      );

      return this;

    }); // details-table

    var mod_info = $('div.mod_info');
    var mod_menu = $('div.mod_menu');
    var plus = mod_info.find('div.zoom_plus');
    var minus = mod_info.find('div.zoom_minus');

    $(window).on('resize', function () {
      map.mapster('resize', mod_info.width());
    });

    map.mapster('resize', mod_info.width());

    plus.on('click', 'a', function (event) {

      event.preventDefault();

      mod_info.addClass('notransition');
      mod_menu.addClass('block_zoom');

      mod_info.css('width', '100%');
      mod_info.css('float', 'none');

      plus.css('display', 'none');
      minus.css('display', 'block');
      map.mapster('resize', $('div.mod_info').width());

      mod_info.removeClass('notransition');

    });

    minus.on('click', 'a', function (event) {

      event.preventDefault();

      mod_info.addClass('notransition');

      mod_info.css('width', '58%');
      mod_info.css('float', 'right');

      plus.css('display', 'block');
      minus.css('display', 'none');
      map.mapster('resize', $('div.mod_info').width());

      mod_info.removeClass('notransition');
      mod_menu.removeClass('block_zoom');

    });

    //
    // Фильтр по дереву каталогов (ВИН-кода)
    //
    $('div.mod_menu input[type="text"]').keyup(function () {

      var val = String($(this).val());
      var re = new RegExp(val, 'i');
      var el;

      $(".mod_menu ul > li").each(function () {

        el = $(this);

        el.removeClass('active');
        el.css('display', 'block');

        if (val.length > 0) {

          if (el.find('a').text().search(re) < 0) {
            el.css('display', 'none');
          } else {

            el.css('display', 'block');
            el.addClass('active');

            el.parents('li').addClass('active');
            el.parents('li').css('display', 'block');

          }

        } else {
          $('div.search-type-vin div.mod_menu li.font_bold').parents('li').addClass('active');
        }

      });

    });

    // Разворачиваем дерево каталога вин-кодов для выбранной ветки
    $('div.search-type-vin div.mod_menu li.font_bold').parents('li').addClass('active');

    // Снежок
    // new UsrSnowEffect('sky');

    // Скроллинг
    $('.hz_menu__wrapper').mousewheel(function (event, delta) {
      this.scrollLeft -= (delta * 30);
      event.preventDefault();
    });

    // Строка поиска
    $().autocomplete();

    // Реклама
    $('.advertising').adv();

    // Уведомления
    $('#notification_cnt').notifications();

    // Настройки уведомлений
    $('div.notification_settings_block').notifications_settings();

    /*
        $('.notification_alert').each(function () {
            $(this).find('.close_btn').click(function () {
                $(this).parents('.notification_alert').animate({
                    opacity: 0
                }, 300, function () {
                    $(this).hide()
                    $(this).nextAll().css('top', '120px');
                    $('.notification_alert').animate({
                        top: 0
                    }, 300)
                })
            })
        })
    */

    // Включение/выключение применение наценок
    $('body').markup_switcher();

    // Общая наценка
    $('input[data-type="markup-general"]').markup_general();

    // Добавление, редактирование диапазона наценки
    $('div.personalization_block div.discount-range').markup_manager();

    // Панель с информацией по наценке.
    $('a.discount.markup_panel').markup_info_panel();

    $('div.garage').garageManager();

    $('div.maintenance').maintenanceManager();

    // Всплывающее окно товара
    // item.js
    $('main').buttonModalCart('div.product-modal-cart');

    // Обновление общего счетчика корзины
    $('.cart_button_container, .lc_cart_checkout').updateBasketCounter();

    // Создание новой корзины в списке заказов (https://v-avto.ru/baskets)
    // Редактирование заголовка корзины
    $('main div.user_baskets').titleBasketModal();

    // Работа с корзиннами в https://v-avto.ru/baskets
    // baskets.js
    $('main div.multicart_carts div.cart').basketsManager();

    // Загрузка страницы гиф
    $(".loading, .search_type_tabs a:not(.disabled), .product_brand a").click(function () {
      setTimeout(function () {
        $(".loader").show();
      }, 500);
    });   
  }); // $(document).on('ready')

  // Обновить контент текущей страницы после успешного выполнения действия по AJAX
  $(document).on('ajax:success', '.ajax-refresh', function (event) {
    let parent = $(this).closest('[id]');
    let id = parent.attr('id');

    $.ajax({
      url: window.location.href,
      data: {html_id: id},
      type: 'GET',
      beforeSend: function () {
        const width = parent.width();
        const height = parent.height();
        parent.fadeOut(200, function () {
          parent.css({
            'background-color': '#e6e6e6',
            'width': width - 10,
            'height': height - 10
          }).empty().fadeIn();
        });
      },
      success: function (response) {
        if (response.length) {
          parent.fadeOut(200, function () {
            parent.replaceWith($(response));
            newContent.hide().fadeIn(200);
          })
        } else {
          parent.parents('[id]').first().animate({height: 0, opacity: 0}, {
            duration: 200, complete: function () {
              $(this).remove();
            }
          });
        }
      }
    });
  });
})(jQuery);

//fix mobile balance async load
document.addEventListener('render_async_load', function(event) {
  let balance = document.getElementById("desktop_count");
  if (balance != null ) {
    document.getElementById("mobile_count").textContent = balance.textContent;
  }
});
