/**-----------------------------------------------------------------------------
  * JQuery Ajax settings
  *---------------------------------------------------------------------------*/
String.format = function(format, obj) {

  return format.replace(/{\w+}/g, function(p1, offset, s) {
    return obj[ p1.replace(/[{}]/g, '') ];
  });

}; // String#format2

if (typeof String.prototype.indent != "function") {

  String.prototype.indent = function() {

    var parts = this.split('.');
    parts[0]  = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
    return parts.join('.')

  };

}; // String#indent

if (typeof Number.prototype.indent != "function") {

  Number.prototype.indent = function() {
    return (new String(this)).indent();
  };

}; // Number#indent

/**
 * Изменение URL-траницы
 */
window.changeUrl = function() {

  if (typeof(window.history.replaceState) == 'function') {

    return function(url) {
      window.history.replaceState({ path: url }, "", url);
    };

  } else {
    return function(url) {};
  }

}();

/**
 * Рефреш страницы
 */
window.refreshLocation = function(timeout) {

  timeout = Math.abs(Number(timeout, 10) || 500);

  setTimeout(function() {
    window.location.href = window.location.href;
  }, timeout);

}; // refreshLocation

/**
   *
   */
window.requestAnimFrame = (function() {

  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };

})();
